import axios from 'axios'

export default class TemporaryDocumentService {
  static upload = (payload) =>
    axios
      .post(`/temporary-documents/case`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data
      })

  static create = () =>
    axios.post(`/temporary-documents/case/create`).then((response) => {
      return response.data
    })

  static list = (caseUUID) =>
    axios.get(`/temporary-documents/case/${caseUUID}`).then((response) => {
      return response.data
    })

  static download = (caseUUID, fileName) =>
    axios
      .get(`/temporary-documents/case/${caseUUID}/file/${fileName}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => response)

  static delete = (caseUUID, fileName) =>
    axios
      .delete(`/temporary-documents/case/${caseUUID}/file/${fileName}`)
      .then((response) => {
        return response.data
      })

  static caseDelete = (caseUUID) =>
    axios.delete(`/temporary-documents/case/${caseUUID}`).then((response) => {
      return response.data
    })

  static documentToQuote = (caseUUID, quote) =>
    axios
      .post(`/temporary-documents/case/${caseUUID}/migrate/quote/${quote}`)
      .then((response) => {
        return response.data
      })
}
