<template>
  <span>
    <Badge
      v-if="item.quote && item.quote.version"
      variant="soft-primary"
      class="ml-3"
    >
      <RouterLink
        :to="{
          name: 'quotes.documents.list',
          params: { quoteId: item.quote.id },
        }"
      >
        V{{ item.quote.version }}
      </RouterLink>
    </Badge>
    <Badge v-if="item.fileType" variant="soft-secondary" class="ml-3">
      {{ item.fileType }}
    </Badge>
    <Badge v-else variant="soft-secondary" class="ml-3">
      {{ item.name.split('.').pop() }}
    </Badge>
    <Badge v-if="item.signatureStatus()" variant="soft-secondary" class="ml-3">
      {{ __(item.signatureStatus()) }}
    </Badge>
  </span>
</template>

<script>
export default {
  name: 'DirectoryBadge',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
}
</script>