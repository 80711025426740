
<div>
  <b-button
    id="quote-popover-button"
    class="btn-notification dropdown-toggle d-none d-lg-flex align-items-center"
  >
    <icon
      icon="plus-circle"
      class="text-muted text-primary"
      :size="iconSize"
    ></icon>
  </b-button>

  <b-popover
    target="quote-popover-button"
    :triggers="['hover']"
    placement="bottom"
    custom-class="quote-popover"
    @show="loadMissingSchemas"
  >
    <template #title>
      <div class="d-flex justify-content-between align-center pr-1 pl-1">
        <h4 class="mb-0">
          <icon icon="file-alt" class="text-muted"></icon>
          {{ __('quotes.popover-title') }}
        </h4>

        <RouterLink
          :to="{ name: 'quotes.quick-select-schema' }"
          data-testid="quote-view-all"
          class="small"
        >
          {{ __('routes.view-all') }}
        </RouterLink>
      </div>
    </template>

    <hr />

    <b-list-group flush class="my-n3">
      <template v-if="availableSchemas && availableSchemas.length">
        <b-list-group-item
          v-for="(schema, key) in availableSchemas"
          :key="key"
          class="text-reset"
        >
          <b-row class="align-items-center">
            <b-col class="ml-2">
              <RouterLink
                :to="{
                  name: 'quotes.create',
                  params: { schemaId: schema.reference },
                }"
              >
                <div class="small">
                  <strong>{{
                    `${__(schema.name)} v.${schema.version}`
                  }}</strong>
                  <b-badge
                    v-if="schema.product && schema.product.name"
                    variant="soft-primary"
                  >
                    {{ schema.product.name }}
                  </b-badge>
                </div>

                <small
                  v-if="schema.coverholder && schema.coverholder.legalName"
                  class="text-muted"
                >
                  {{ schema.updatedAt }} {{ __('quotes.popover-uw-by') }}
                  {{ schema.coverholder.legalName }}
                </small>
              </RouterLink>
            </b-col>
          </b-row>
        </b-list-group-item>
      </template>

      <template v-else>
        <b-list-group-item class="text-reset text-center">
          <small>{{ __('quotes.popover-no-schemas') }}</small>
        </b-list-group-item>
      </template>
    </b-list-group>

    <OnLoading>
      <div class="d-flex justify-content-center">
        <b-spinner
          variant="primary"
          type="grow"
          label="Spinning"
          class="mt-4"
        ></b-spinner>
      </div>
    </OnLoading>
  </b-popover>
</div>
