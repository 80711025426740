
<div>
  <div class="mr-3 user-entity-popover">
    <div class="font-weight-bold">
      {{ authUser().name }}
    </div>
    <div
      id="user-entity-popover"
      class="d-flex align-items-center cursor-pointer"
    >
      <div class="entity-name">
        {{ lodash.get(authUser(), 'entity.name') }}
      </div>
      <Icon
        v-if="!!authUser().relatedEntities.length"
        class="grey ml-1"
        icon="angle-down"
      />
    </div>
  </div>

  <b-popover
    v-if="!!authUser().relatedEntities.length"
    v-can:users.entities.update
    target="user-entity-popover"
    :triggers="['hover']"
    placement="bottom"
  >
    <b-list-group flush class="my-n3">
      <b-list-group-item>
        <div v-if="authUser().entityId === authUser().defaultEntityId">
          {{ lodash.get(authUser(), 'defaultEntity.name') }}
        </div>
        <b-button
          v-else
          variant="link"
          size="sm"
          class="p-0"
          @click="switchSelectedEntity(authUser().defaultEntity)"
        >
          {{ lodash.get(authUser(), 'defaultEntity.name') }}
        </b-button>
      </b-list-group-item>
      <b-list-group-item
        v-for="(entity, key) in authUser().relatedEntities"
        :key="key"
      >
        <div v-if="entity.id === authUser().entityId">
          {{ entity.name }}
        </div>
        <b-button
          v-else
          variant="link"
          size="sm"
          class="p-0"
          @click="switchSelectedEntity(entity)"
        >
          {{ entity.name }}
        </b-button>
      </b-list-group-item>
    </b-list-group>
  </b-popover>
</div>
