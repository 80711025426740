
<b-navbar class="d-none d-lg-flex pl-0 pr-0" type="light">
  <b-container fluid>
    <RouterLink :to="{ name: 'home' }" class="navbar-brand">
      <img
        :alt="setting('ui_name')"
        :src="setting('ui_logo')"
        class="navbar-brand-img img-fluid"
      />
    </RouterLink>

    <div
      :class="{
        'navbar-user': true,
        'd-flex': true,
        'w-100': $can('system.global-search'),
      }"
    >
      <GlobalSearch v-can:system.global-search class="mx-5 flex-fill" />
      <UserEntityPopover />
      <UserDropdown />
      <NotificationPopover />
      <ActivityPopover v-can:activities.view />
      <CreateQuotePopover v-can:quotes.create class="mr-3" />
      <HelpButton v-can:system.help />
    </div>
  </b-container>
</b-navbar>
