<template>
  <div
    class="form-group position-relative col-auto"
    :class="{ [uiOptions.class]: true }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <Toggler
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :readonly="readOnly"
      :label="node.label"
      :quote-node="node"
      :description="node.description"
      :name="name"
      :required="uiOptions.required"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeToggler',

  mixins: [UiTypeMixin],
}
</script>
