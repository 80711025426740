import FileManager from '@/General/FileManager.js'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    directoryStyle() {
      return { paddingLeft: 24 * this.item.depth + 'px', paddingRight: 0 }
    },
  },

  methods: {
    getFileTypeIcon(item) {
      if (item.isFolder) {
        return FileManager.getFolderTypeIcon()
      }
      return FileManager.getFileTypeIcon(item.fileName)
    },
  },
}
