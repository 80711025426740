<template>
  <div
    v-if="uiOptions.visible"
    :class="uiOptions.class"
    class="position-relative"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <SelectField
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="__(node.description)"
      :options="node.options"
      :name="name"
      v-bind="$attrs"
      :required="uiOptions.required"
      :readonly="readOnly || !!node.value"
      :placeholder="uiOptions.placeholder"
      :datamask="uiOptions.datamask"
      :allow-empty="false"
      :preserve-search="true"
      :quote-node="node"
      :class="{ 'pending-selection': lastQuery }"
      :disable-append="readOnly && !node.value"
      :tooltip="node.uiProperties.tooltip"
      :highlight="node.uiProperties.highlight"
      @onChange="clientSelected"
      @onOpen="updateSearchValue"
    >
      <template #append>
        <ClientAppend
          v-if="!readOnly"
          :node="node"
          :on-remove="removeValue"
          :on-edit="openClientEditModal"
          :on-create="openClientCreateModal"
        />
        <ClientAppend v-else :node="node" />
      </template>
    </SelectField>
    <slot name="node-actions" />
  </div>
</template>

<script>
import ClientAppend from '@/Modules/Quote/Components/QuoteForm/Elements/UiTypeClient/ClientAppend.vue'
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeClient',

  components: { ClientAppend },

  mixins: [UiTypeMixin],

  data() {
    return {
      lastQuery: null,
    }
  },

  methods: {
    updateSearchValue() {},

    removeValue() {},

    clientSelected() {},

    openClient() {},

    openClientCreateModal() {},

    openClientEditModal() {},
  },
}
</script>

<style lang="scss">
.pending-selection {
  .multiselect {
    input.multiselect__input:not(:focus) {
      color: red !important;
    }
  }

  .multiselect__input:focus {
    color: rgb(91, 106, 123);
  }
}
</style>
