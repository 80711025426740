<template>
  <b-list-group-item class="text-reset">
    <b-row :class="{ [rowClass]: !!rowClass }">
      <b-col>
        <small class="text-muted">
          {{ label }}
        </small>
      </b-col>

      <b-col :cols="newLine ? '12' : 'auto'">
        <slot />
        <pre v-if="html" v-html="html" />
        <div v-else v-text="value" />
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'DataGroupItem',

  props: {
    label: {
      type: String,
      default: null,
      required: true,
    },
    value: {
      type: String | Number,
      default: null,
    },
    newLine: {
      type: Boolean,
      default: false,
    },
    html: {
      type: String,
      default: null,
    },
    rowClass: {
      type: String,
      default: 'align-items-center',
    },
  },
}
</script>
