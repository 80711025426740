
<div>
  <DefaultHeader />

  <div class="main-content">
    <b-container>
      <PreviewBlock />
      <RouterView />
    </b-container>
  </div>
</div>
