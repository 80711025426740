import App from '@/Modules/Base/App.vue'
import ErrorPage from '@/Modules/Base/Pages/ErrorPage.vue'
import router from '@/Setup/Routes/SetupRouter.js'
import setupAppData from '@/Setup/SetupAppData.js'
import store from '@/Setup/SetupStore.js'
import '@innologica/vue-stackable-modal/src/assets/transitions/translate-fade.scss'
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import VueCurrencyInput from 'vue-currency-input'
import ReactiveProvide from 'vue-reactive-provide'
import { i18n } from '@/Setup/SetupI18n.js'
import VueClipboard from 'vue-clipboard2'
import SetupMainAppPlugins from '@/Setup/SetupMainAppPlugins.js'
import _ from 'lodash'
import axios from 'axios'
import './initiateApp'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import 'handsontable/dist/handsontable.full.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import '@iconscout/unicons/css/line.css'
import 'pretty-print-json/pretty-print-json.css'
import 'jsondiffpatch/dist/formatters-styles/html.css'
import FileManager from '@/General/FileManager.js'

FileManager.preloadFileTypeIcons()

// VeeValidate setup
Vue.use(VeeValidate, {
  events: '',
  fieldsBagName: 'veeFields',
})
Vue.use(ReactiveProvide)

// Currency field
Vue.use(VueCurrencyInput)

Vue.use(VueClipboard)
Vue.use(SetupMainAppPlugins)

window.Vue = Vue
window._ = _
window.axios = axios

if (import.meta.env.APP_DEBUG) {
  Vue.config.devtools = true
}

setupAppData()
  .then(() => {
    // Create Vue instance
    new Vue({
      store,
      router,
      i18n,
      el: '#app',
      render: (h) => h(App),
    })
  })
  .catch(() => {
    // Create Vue instance
    new Vue({
      store,
      router,
      i18n,
      el: '#app',
      render: (h) => h(ErrorPage),
    })
  })
