
<div
  v-if="uiOptions.visible"
  class="position-relative"
  :class="uiOptions.class"
  :draggable="isDraggable"
  @dragstart="(event) => $emit('drag-start', event)"
  @dragend="(event) => $emit('drag-end', event)"
>
  <CurrencyField
    :ref="snakeKey"
    v-model="node.value"
    :visible="uiOptions.visible"
    :label="__(node.label)"
    :description="__(node.description)"
    :icon="uiOptions.icon"
    step="0.01"
    :precision="uiOptions.precision"
    :quote-node="node"
    :name="name"
    :min="uiOptions.min"
    :max="uiOptions.max"
    :required="uiOptions.required"
    :readonly="readOnly"
    :placeholder="__(uiOptions.placeholder)"
    :datamask="uiOptions.datamask"
    v-bind="$attrs"
    :input-class="node.uiProperties.inputClass"
    :highlight="node.uiProperties.highlight"
    :tooltip="node.uiProperties.tooltip"
    :hint="__(node.uiOptions.hint)"
    :ui-rules-error="node.uiRulesError"
    @input="$emit('input', $event)"
  >
    <template v-if="node.uiProperties.append" slot="append">
      <div class="input-group-append">
        <span :class="{ 'input-group-text': true, readonly: readOnly }">
          {{ node.uiProperties.append }}
        </span>
      </div>
    </template>
  </CurrencyField>
  <slot name="node-actions" />
</div>
