<template>
  <div>
    <b-button
      v-b-tooltip:hover="__('schemas.help')"
      class="btn-notification"
      @click="handleHelpButtonClick"
    >
      <Icon icon="question-circle" class="text-muted" :size="iconSize" />
    </b-button>
  </div>
</template>

<script>
import PopOverMixin from '@/Modules/Base/Mixins/PopOverMixin.js'
import { TOGGLE_HELP_ACTION, HELP_GETTER } from '@/Modules/Base/BaseModule.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NotificationPopover',

  mixins: [PopOverMixin],

  computed: {
    ...mapGetters('BaseModule', {
      help: HELP_GETTER,
    }),
    unreadNotificationsCount() {
      return this.authUser().unreadNotificationsCount
    },
  },

  methods: {
    ...mapActions('BaseModule', {
      toggleHelp: TOGGLE_HELP_ACTION,
    }),

    handleHelpButtonClick() {
      this.toggleHelp(!this.help.visible)
    },
  },
}
</script>