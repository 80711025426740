
<div
  class="position-relative col-auto"
  :class="{ [uiOptions.class]: true }"
  :draggable="isDraggable"
  @dragstart="(event) => $emit('drag-start', event)"
  @dragend="(event) => $emit('drag-end', event)"
>
  <EditorField
    :ref="snakeKey"
    v-model="content"
    :visible="uiOptions.visible"
    :label="__(node.label)"
    :description="__(node.description)"
    :icon="uiOptions.icon"
    :required="uiOptions.required"
    :readonly="readOnly"
    :name="name"
    :placeholder="__(uiOptions.placeholder)"
    :highlight="node.uiProperties.highlight"
    :tooltip="node.uiProperties.tooltip"
    :tooltip-class="node.uiProperties.tooltipClass"
    v-bind="$attrs"
    :editor-options="editorOptions"
    :height="editorHeight"
    :disabled="readOnly"
    :quote-node="node"
    :hint="__(node.uiOptions.hint)"
  />
  <input type="hidden" :name="name" :value="node.value" />
  <slot name="node-actions" />
</div>
