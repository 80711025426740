<template>
  <div
    v-if="uiOptions.visible"
    class="position-relative"
    :class="{
      'form-group': true,
      'col-padding': !uiOptions.classColumn,
      [uiOptions.classColumn]: !!uiOptions.classColumn,
    }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <label v-if="uiOptions.classColumn" class="mb-n4"></label>
    <VueButton
      type="button"
      :name="node.fieldName"
      :custom-class="uiOptions.class || 'btn-primary'"
      :lift="uiOptions.lift"
      :icon="uiOptions.icon"
      :label="__(node.label)"
      :description="__(node.description)"
      :disabled="uiOptions.readonly"
      class="w-100"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeIntegrationButton',

  mixins: [UiTypeMixin],
}
</script>
