
<div>
  <b-button
    id="notification-popover-button"
    class="btn-notification dropdown-toggle mr-3 d-none d-lg-flex align-items-center"
  >
    <icon icon="bell" class="text-muted" :size="iconSize"></icon>

    <b-badge
      v-if="unreadNotificationsCount"
      variant="danger"
      class="badge-notification"
    >
      {{ applyCompactNotation(unreadNotificationsCount) }}
    </b-badge>
  </b-button>

  <b-popover
    target="notification-popover-button"
    :triggers="['hover', 'click']"
    placement="bottom"
    custom-class="notification-popover"
    @show="fetchLatestNotifications()"
  >
    <template #title>
      <div class="d-flex justify-content-between align-center mt-2">
        <h4 class="mb-0">
          <icon icon="bell" class="text-muted" />
          {{ __('notifications.popover-title') }}
        </h4>
        <RouterLink
          :to="{ name: 'notifications.list' }"
          class="small align-self-center"
        >
          {{ __('routes.view-all') }}
        </RouterLink>
      </div>
    </template>

    <hr />

    <b-list-group v-if="notificationsLoaded" flush class="my-n3">
      <b-list-group-item
        v-for="(notification, key) in notifications"
        :key="key"
        class="text-reset notification-popover__wrapper"
        @click="goToDetails(notification)"
      >
        <b-row class="align-items-center">
          <b-col cols="auto">
            <div class="text-primary">
              <div class="notification-circle">
                <icon
                  :icon="notification.data.icon || 'bell'"
                  :class="{ 'text-muted': notification.readAt }"
                />
              </div>
            </div>
          </b-col>

          <b-col class="ml-n3">
            <div class="small notification-popover__wrapper__title">
              <strong
                :class="{
                  'text-muted': notification.readAt,
                  'dark-color': true,
                }"
              >
                {{ notification.data.title }}
              </strong>
            </div>

            <small
              :class="{
                'text-muted': notification.readAt,
                'dark-color': true,
              }"
            >
              {{ notification.createdAt }}
            </small>
          </b-col>
        </b-row>
      </b-list-group-item>
      <span v-if="!notifications.length" class="mb-2">
        {{ __('notifications.popover-no-notifications') }}
      </span>
    </b-list-group>
  </b-popover>
</div>
