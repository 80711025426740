<template>
  <div v-if="isPreview && customCssEnabled" class="position-fixed preview-div">
    <b-alert
      :show="isPreview"
      variant="warning"
      class="d-flex align-items-center justify-content-between rounded-0"
      :style="{ height: '50px' }"
    >
      <div>{{ __('custom-css.preview-mode-text') }}</div>
      <b-button variant="dark" size="sm" @click="exitPreview">
        {{ __('custom-css.exit-preview-mode') }}
      </b-button>
    </b-alert>
  </div>
</template>

<script>
import { cssPreviewMode } from '@/Modules/CustomCSS/CustomCSSConstants.js'

export default {
  computed: {
    isPreview: function () {
      const url = new URL(window.location.href)

      return url.searchParams.has(cssPreviewMode)
    },

    customCssEnabled() {
      return this.setting('ui_custom_css_enabled')
    },
  },

  watch: {
    customCssEnabled(newValue) {
      if (!newValue) {
        if (document.body.classList.contains(this.previewClass)) {
          this.removeBodyClass(this.previewClass)
        }
        return
      }

      const url = new URL(window.location.href)
      if (
        url.searchParams.has(cssPreviewMode) &&
        !document.body.classList.contains(this.previewClass)
      ) {
        this.addBodyClass(this.previewClass)
      }
    },
  },

  mounted() {
    if (!this.customCssEnabled) {
      if (document.body.classList.contains(this.previewClass)) {
        this.removeBodyClass(this.previewClass)
      }
      return
    }

    const url = new URL(window.location.href)

    if (url.searchParams.has(cssPreviewMode)) {
      this.addBodyClass(this.previewClass)
      return
    }

    this.removeBodyClass(this.previewClass)
  },

  created() {
    this.previewClass = 'preview-mode'
  },

  methods: {
    exitPreview() {
      const url = new URL(window.location.href)
      url.searchParams.delete(cssPreviewMode)

      location.replace(url.href)
    },

    addBodyClass(className = '') {
      if (!document.body) {
        return
      }
      document.body.classList.add(className)
    },

    removeBodyClass(className = '') {
      if (!document.body) {
        return
      }
      document.body.classList.remove(className)
    },
  },
}
</script>
<style lang="scss">
.preview-mode {
  padding-top: 50px;
}
@media (min-width: 992px) {
  .preview-mode {
    .navbar-toggler-persistent {
      top: 70px;
    }

    .navbar-vertical.navbar-expand-lg {
      top: 50px !important;
    }
  }
}
.preview-div {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  overflow: hidden;
  height: 50px;
}
</style>
