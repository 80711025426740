<template>
  <div class="d-flex align-items-center justify-content-end actionpopover">
    <b-button
      :id="id"
      size="xs"
      variant="outline-secondary"
      :data-testid="dataTestid"
      class="dropdown-ellipses dropdown-toggle ml-2"
      @click.prevent
    >
      <Icon icon="ellipsis-v" size="18" />
    </b-button>

    <b-popover
      :target="id.toString()"
      triggers="hover click blur"
      :placement="placement"
      :delay="delay"
      :custom-class="customClass"
    >
      <slot />
    </b-popover>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ActionPopover',

  props: {
    id: {
      default: uuidv4(),
    },
    placement: {
      type: String,
      default: 'left',
    },
    delay: {
      type: Object,
      default: () => {
        return { show: 50, hide: 300 }
      },
    },
    dataTestid: {
      type: String,
      default: 'dropdown',
    },
    customClass: {
      type: String,
      default: 'p-2',
    },
  },
}
</script>
<style scoped lang="scss">
.dropdown-ellipses {
  padding: 0;

  i {
    margin-right: -2px;
    margin-left: -2px;
  }
}
</style>
