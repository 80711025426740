
<div
  class="position-relative col-auto"
  :class="{ [uiOptions.class]: true }"
  :draggable="isDraggable"
  @dragstart="(event) => $emit('drag-start', event)"
  @dragend="(event) => $emit('drag-end', event)"
>
  <SelectField
    :ref="snakeKey"
    v-model="node.value"
    :visible="uiOptions.visible"
    :label="__(node.label)"
    :description="__(node.description)"
    :options="translatedOptions"
    :quote-node="node"
    :name="name"
    v-bind="$attrs"
    :required="uiOptions.required"
    :readonly="readOnly"
    :placeholder="__(uiOptions.placeholder)"
    :datamask="uiOptions.datamask"
    :multiple="true"
    :allow-empty="true"
    :taggable="true"
    :open-direction="node.uiProperties.openDirection"
    :hint="__(node.uiOptions.hint)"
    :ui-rules-error="node.uiRulesError"
    @onChange="selectChanged"
    @onOpen="loadOptionsIfNotLoaded(node.datasetTag)"
  />
  <slot name="node-actions" />
</div>
