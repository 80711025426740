
<div>
  <b-button
    v-b-tooltip:hover="__('schemas.help')"
    class="btn-notification"
    @click="handleHelpButtonClick"
  >
    <Icon icon="question-circle" class="text-muted" :size="iconSize" />
  </b-button>
</div>
