<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-start global-search-wrapper"
    >
      <div class="input-group input-group-flush">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="fe fe-search"></i>
          </span>
        </div>
        <input
          ref="globalSearchFieldRef"
          v-model="q"
          name="q"
          class="form-control mb-0 w-100 border-0"
          data-testid="search-input"
          type="search"
          :placeholder="__('common.search')"
          @keydown="onKeyDown"
          @input="debounceSearch"
          @focusin="handleFocusInInput"
          @focusout="handleFocusOutInput"
        />
        <div v-if="showDropdown" class="input-group-append">
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="
              showDropdown = false
              q = ''
            "
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>

    <GlobalSearchDropdown
      v-if="showDropdown"
      v-click-outside="handleFocusOutDropdown"
      :groups="groupedResults"
      @dropdownItemClick="redirectToSearchedItem"
      @open="onDropdownOpen"
    />
  </div>
</template>
<script>
import GlobalSearchService from '@/Modules/GlobalSearch/GlobalSearchService.js'
import GlobalSearchUtilities from '@/Modules/GlobalSearch/GlobalSearchUtilities.js'
import GlobalSearchDropdown from '@/Modules/GlobalSearch/Components/GlobalSearchDropdown.vue'
import { KEY_ENTER, KEY_DOWN, KEY_UP } from '@/Modules/GlobalSearch/KeyCode.js'
import * as Sentry from '@sentry/vue'

export default {
  name: 'GlobalSearch',

  components: { GlobalSearchDropdown },

  data() {
    return {
      q: '',
      showDropdown: false,
      results: [],
      activeIndex: -1,
    }
  },

  computed: {
    groupedResults() {
      return _.groupBy(this.results, 'type')
    },
  },

  created() {
    this.debounceSearch = _.debounce(this.search, 500)
  },

  methods: {
    search() {
      if (this.q === '') {
        this.showDropdown = false
        this.results = []
        return
      }

      GlobalSearchService.search(this.q).then((response) => {
        this.results = response.data

        if (_.isEmpty(this.results)) {
          return
        }

        this.showDropdown = true
      })
    },

    redirectToSearchedItem(type, item) {
      this.$router
        .push(GlobalSearchUtilities.dataByType(type, item.searchable).url)
        .then(() => {
          this.showDropdown = false
          this.q = ''
          this.results = []
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
    },

    handleFocusInInput() {
      this.focusedIn = true

      if (this.q === '' || _.isEmpty(this.results)) {
        return
      }

      this.showDropdown = true
    },

    handleFocusOutInput() {
      this.focusedIn = false
    },

    handleFocusOutDropdown() {
      if (this.focusedIn) {
        return
      }

      this.showDropdown = false
    },

    onDropdownOpen() {
      this.activeIndex = -1

      this.results = this.results.map((item) => {
        item.active = false
        return item
      })
    },

    onKeyDown(event) {
      const key = event.key

      switch (key) {
        case KEY_ENTER:
          this.onEnterKeyPress()
          break
        case KEY_DOWN:
        case KEY_UP:
          this.onArrowKeyPress(key)
          break
        default:
          break
      }
    },

    onEnterKeyPress() {
      if (this.results.length === 1) {
        let item = this.results[0]
        if (item) {
          this.redirectToSearchedItem(item.type, item)
        }

        return
      }

      if (this.activeIndex !== -1) {
        let item = this.results[this.activeIndex]
        if (item) {
          this.redirectToSearchedItem(item.type, item)
        }
      }
    },

    onArrowKeyPress(key) {
      if (!key) {
        return
      }

      if (key === KEY_DOWN) {
        if (this.activeIndex + 1 <= this.results.length) {
          this.activeIndex += 1
        }

        if (this.activeIndex + 1 > this.results.length) {
          this.activeIndex = 0
        }
      }

      if (key === KEY_UP) {
        if (this.activeIndex >= 0) {
          this.activeIndex -= 1
        }

        if (this.activeIndex === -1) {
          this.activeIndex = this.results.length - 1
        }
      }

      this.results = this.results.map((item, index) => {
        item.active = false

        if (index === this.activeIndex) {
          item.active = true
        }

        return item
      })
    },
  },
}
</script>
<style lang="scss">
.global-search-wrapper {
  position: relative;
}
</style>
