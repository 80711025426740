
<div>
  <div
    class="d-flex align-items-center justify-content-start global-search-wrapper"
  >
    <div class="input-group input-group-flush">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fe fe-search"></i>
        </span>
      </div>
      <input
        ref="globalSearchFieldRef"
        v-model="q"
        name="q"
        class="form-control mb-0 w-100 border-0"
        data-testid="search-input"
        type="search"
        :placeholder="__('common.search')"
        @keydown="onKeyDown"
        @input="debounceSearch"
        @focusin="handleFocusInInput"
        @focusout="handleFocusOutInput"
      />
      <div v-if="showDropdown" class="input-group-append">
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="
            showDropdown = false
            q = ''
          "
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
  </div>

  <GlobalSearchDropdown
    v-if="showDropdown"
    v-click-outside="handleFocusOutDropdown"
    :groups="groupedResults"
    @dropdownItemClick="redirectToSearchedItem"
    @open="onDropdownOpen"
  />
</div>
