<template>
  <div class="list-group-item-wrapper">
    <DirectoryListItemTemplate
      :style="directoryStyle"
      :item="{ item }"
      collapsable
    >
      <template v-if="showCollapse" #collapsable>
        <div
          v-b-toggle="collapseId"
          class="cursor-pointer"
          @click="handleExpandCollapseClick(item)"
        >
          <Icon v-if="expanded" icon="angle-down" size="14" />
          <Icon v-else icon="angle-right" size="14" />
        </div>
      </template>
      <template #iconBlock>
        <img :src="getFileTypeIcon(item)" :alt="item.name" width="48" />
      </template>
      <template #label>
        <a
          v-can:quotes.documents.view
          href="#"
          @click.prevent="handleExpandFolderClick(item)"
        >
          {{ item.name }}
        </a>
      </template>
      <template #badge>
        <DirectoryBadge :item="item" />
      </template>
      <template #description>
        <DocumentRowSummary v-if="item" :document="item" />
      </template>
      <template #actionButtons>
        <ActionPopover
          :id="'directory-' + item.id"
          v-can-any="['documents.update', 'documents.destroy']"
        >
          <b-button
            v-can:documents.update
            class="dropdown-item"
            size="sm"
            @click="handleEditFolderClick(item)"
          >
            <Icon icon="edit" class="mr-2" size="14" />
            {{ __('buttons.edit') }}
          </b-button>
          <b-button
            v-can:documents.destroy
            class="dropdown-item"
            size="sm"
            @click="removeFolder(item.id)"
          >
            <Icon icon="trash" class="mr-2" size="14" />
            {{ __('buttons.delete') }}
          </b-button>
          <b-button
            v-can:quotes.documents.view
            class="dropdown-item"
            size="sm"
            @click="handleOpenFolderClick(item)"
          >
            <Icon icon="link" class="mr-2" size="14" />
            {{ __('common.open') }}
          </b-button>
        </ActionPopover>
      </template>
    </DirectoryListItemTemplate>
    <b-collapse :id="collapseId" v-model="expanded">
      <DirectoryNode v-for="node in item.nodes" :key="node.id" :item="node" />
    </b-collapse>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import DirectoryListItemTemplate from '@/Modules/Quote/QuoteDocument/Components/DirectoryListItemTemplate.vue'
import DirectoryMixin from '@/Modules/Quote/QuoteDocument/Components/DirectoryMixin.js'
import QuotePrefixedRouteMixin from '@/Modules/Quote/Mixins/QuotePrefixedRouteMixin.js'
import DocumentRowSummary from '@/Modules/Document/Components/DocumentRowSummary.vue'
import DirectoryBadge from '@/Modules/Quote/QuoteDocument/Components/DirectoryBadge.vue'
import EditDocumentFolderModal from '@/Modules/Quote/QuoteDocument/Components/EditDocumentFolderModal.vue'
import QuoteDocumentService from '@/Modules/Quote/QuoteDocument/QuoteDocumentService.js'

export default {
  name: 'DirectoryFolder',

  components: { DirectoryListItemTemplate, DocumentRowSummary, DirectoryBadge },

  mixins: [DirectoryMixin, QuotePrefixedRouteMixin],

  inject: ['onDirectoryOpen', 'onEditFolderSuccess', 'onRemoveFolderSuccess'],

  data() {
    return {
      expanded: false,
    }
  },

  computed: {
    collapseId() {
      return uuidv4()
    },

    showCollapse() {
      return true
    },
  },

  methods: {
    handleExpandCollapseClick(item, event) {
      if (_.isEmpty(this.item.nodes))
        this.onDirectoryOpen && this.onDirectoryOpen(item)
    },

    handleExpandFolderClick(item) {
      this.onDirectoryOpen && this.onDirectoryOpen(item)
      this.expanded = true
    },

    handleEditFolderClick(item) {
      this.showModal({
        component: EditDocumentFolderModal,
        label: this.__(`documents.edit-folder`),
        icon: 'folder',
        class: 'modal-sm',
        meta: {
          parentFolderId: item.parentFolderId ?? this.$route.params.folderId,
          quoteId: this.$route.params.quoteId,
          documentFolder: {
            id: item.id,
            name: item.name,
          },
        },
        onSuccess: () => {
          this.onEditFolderSuccess()
        },
      })
    },

    removeFolder(id) {
      this.$showConfirmationModal(
        this.__(`documents.folder-confirmation-delete`),
        () => {
          QuoteDocumentService.deleteFolder(this.$route.params.quoteId, id)
            .then(() => {
              this.$successToast(this.__(`documents.folder-delete-success`))
              this.onRemoveFolderSuccess()
            })
            .catch((error) => {
              this.$errorToast(error.response.data.message)
            })
        }
      )
    },

    handleOpenFolderClick(item) {
      if (!item) return

      this.$navigate(this.getPrefixedRoute('documents.list.folder'), {
        folderId: item.id,
      })
    },
  },
}
</script>

<style lang="scss">
.list-group-item-wrapper {
  border-bottom: 1px solid #e3ebf6;

  &:last-child {
    border-bottom: none;
  }

  .cursor-pointer {
    cursor: pointer;
    color: #2c7be5;
  }
}
</style>
