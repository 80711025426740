<template>
  <div>
    <b-button
      class="btn-notification dropdown-toggle mr-3 d-none d-lg-flex align-items-center"
      :to="{ name: 'activities.list' }"
    >
      <icon icon="schedule" class="text-muted" :size="iconSize"></icon>

      <b-badge
        v-if="authUser().overdueActivitiesCount"
        variant="secondary"
        class="badge-notification"
      >
        {{ applyCompactNotation(authUser().overdueActivitiesCount) }}
      </b-badge>
    </b-button>
  </div>
</template>

<script>
import PopOverMixin from '@/Modules/Base/Mixins/PopOverMixin.js'
import { applyCompactNotation } from '@/General/applyNumberFormatting.js'

export default {
  name: 'ActivityPopover',

  mixins: [PopOverMixin],

  methods: {
    applyCompactNotation(value) {
      return applyCompactNotation(value, this.setting('formatting_value'))
    },
  },
}
</script>

<style lang="scss">
.activity-popover {
  max-width: 350px;
  width: 350px;
}
.badge-notification {
  z-index: 1;
}
</style>
