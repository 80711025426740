
<div
  v-if="uiOptions.visible"
  :id="id"
  class="mb-3 pb-2"
  :class="{ [uiOptions.classColumn]: true, 'col-12': !uiOptions.classColumn }"
  :draggable="isDraggable"
  @dragstart="(event) => $emit('drag-start', event)"
  @dragend="(event) => $emit('drag-end', event)"
>
  <div class="position-absolute section-toggler">
    <b-form-checkbox
      v-if="showTogglable"
      v-model="toggled"
      size="sm"
      @change="toggle"
    />
  </div>

  <div
    v-if="node.label"
    v-b-popover.hover.html.topleft="
      traceMode
        ? `<div style='width:500px'>${prettyPrintJson.toHtml(getTrace(node), {
          quoteKeys: true,
        })}</div>`
        : false
    "
    :class="{ dimmed: showTogglable && !toggled, 'mb-3': true }"
  >
    <h2 :class="`header-title ${uiOptions.class}`">
      {{ __(node.label) }}
    </h2>

    <small class="form-text text-muted pt-1">{{
      __(node.description)
    }}</small>
    <small v-if="traceMode" class="trace-dot-key">{{ traceLabel }}</small>
  </div>

  <div class="header-line mt-n2">
    <div class="position-relative">
      <a
        v-if="
          (showTogglable && toggled && showCollapsable) ||
            (showCollapsable && toggled)
        "
        href="#"
        class="collapseLink position-absolute mr-2"
        @click.prevent="toggleCollapsable"
      >
        <Icon v-if="expanded" icon="angle-up" size="16" />
        <Icon v-else icon="angle-down" size="16" />
      </a>

      <hr style="height: 1px; width: 100%" />
    </div>
  </div>

  <b-collapse :id="`${snakeKey}_collapse`" v-model="expanded">
    <div v-if="!showTogglable || toggled" class="row section-body">
      <SchemaDesignerNode
        v-for="(property, key) in node.properties"
        :key="key"
        :node="property"
      />
    </div>
  </b-collapse>
  <slot name="node-actions" />
</div>
