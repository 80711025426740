
<div
  class="form-group position-relative col-auto"
  :class="{ [uiOptions.class]: true }"
  :draggable="isDraggable"
  @dragstart="(event) => $emit('drag-start', event)"
  @dragend="(event) => $emit('drag-end', event)"
>
  <Radios
    :ref="snakeKey"
    v-model="node.value"
    :readonly="readOnly"
    :visible="uiOptions.visible"
    :inline-control="node.uiProperties.inlineControl"
    :label="node.label"
    :quote-node="node"
    :options="translatedOptions"
    :required="uiOptions.required"
    :description="node.description"
    :name="name"
    :highlight="node.uiProperties.highlight"
    :tooltip="node.uiProperties.tooltip"
  />
  <slot name="node-actions" />
</div>
