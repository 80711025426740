<template>
  <div class="d-flex align-items-center wrap-text">
    <span>{{ quote.number }} |</span>
    <span v-if="quote.status" :class="quote.status.class" class="badge mx-2">
      {{ __(quote.status.name) }}
    </span>
    <span>| {{ quote.client }}</span>
  </div>
</template>
<script>
import SearchRowMixin from '@/Modules/GlobalSearch/Components/SearchRowMixin.js'
import QuoteModel from '@/Modules/Quote/QuoteModel.js'

export default {
  name: 'QuoteRowComponent',

  mixins: [SearchRowMixin],

  data() {
    return {
      quote: new QuoteModel(this.model || {}),
    }
  },
}
</script>
