<template>
  <div class="canvas row position-relative pt-4">
    <SchemaDesignerNode
      v-for="(property, key) in node.properties"
      :key="key"
      :node="property"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
<style scoped>
.canvas {
  border: 1px solid #e3ebf6;
  min-height: 50px;
}
</style>