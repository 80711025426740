export const KeyCode = Object.freeze({
  CODE_ENTER: 'Enter',
  CODE_UP: 'ArrowUp',
  CODE_DOWN: 'ArrowDown',
})

export const KEY_ENTER = KeyCode.CODE_ENTER
export const KEY_DOWN = KeyCode.CODE_DOWN
export const KEY_UP = KeyCode.CODE_UP

export default KeyCode
