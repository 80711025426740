<template>
  <div
    v-if="uiOptions.visible"
    :class="uiOptions.class"
    class="position-relative"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <SelectField
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="__(node.description)"
      :options="node.options"
      :name="name"
      v-bind="$attrs"
      :required="uiOptions.required"
      :readonly="readOnly || !!node.value"
      :placeholder="__(uiOptions.placeholder)"
      :datamask="uiOptions.datamask"
      :allow-empty="false"
      :preserve-search="false"
      :quote-node="node"
      :disable-append="readOnly && !node.value"
      :open-direction="node.uiProperties.openDirection"
      :hint="__(node.uiOptions.hint)"
    >
      <template #append>
        <ClientAppend v-if="!readOnly" :node="node" :on-create="onCreate" />
        <ClientAppend v-else :node="node" />
      </template>
    </SelectField>
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'
import ClientAppend from '@/Modules/Quote/Components/QuoteForm/Elements/UiTypeClient/ClientAppend.vue'

export default {
  name: 'UiTypeEntity',

  components: { ClientAppend },

  mixins: [UiTypeMixin],

  methods: {
    onCreate() {},
  },
}
</script>
