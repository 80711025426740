<template>
  <Component :is="getComponent(item)" :item="item" />
</template>
      
<script>
import DirectoryFolder from '@/Modules/Quote/QuoteDocument/Components/DirectoryFolder.vue'
import DirectoryFile from '@/Modules/Quote/QuoteDocument/Components/DirectoryFile.vue'

export default {
  name: 'DirecroryNode',

  components: { DirectoryFolder, DirectoryFile },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    getComponent(item) {
      if (item.isFolder) return 'DirectoryFolder'

      return 'DirectoryFile'
    },
  },
}
</script>
      