<template>
  <div>
    <template
      v-if="path && path !== 'auth.redirect-special-role-user' && $authCheck()"
    >
      <CoverholderApp v-if="authUser().isCoverholder()" />
      <DefaultApp v-else />
      <ActivityMonitor />
    </template>

    <template v-else>
      <div
        class="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
        style="height: 100vh"
      >
        <b-container>
          <RouterView />
        </b-container>
      </div>
    </template>

    <Loader />
    <Modal />
    <ConfirmationModal />
    <InformationToast />
  </div>
</template>

<script>
import InformationToast from '@/General/InformationToast.vue'
import Loader from '@/General/Loader.vue'
import ConfirmationModal from '@/General/Modal/ConfirmationModal.vue'
import {
  AUTH_USER_GETTER,
  GET_AUTH_USER_ACTION,
} from '@/Modules/Auth/AuthModule.js'
import CoverholderApp from '@/Modules/Base/Components/CoverholderApp.vue'
import DefaultApp from '@/Modules/Base/Components/DefaultApp.vue'
import { GET_SETTINGS_ACTION } from '@/Modules/Base/SettingModule.js'
import store from '@/Setup/SetupStore.js'
import ActivityMonitor from '@/Modules/Base/Components/ActivityMonitor.vue'

export default {
  name: 'App',

  components: {
    InformationToast,
    DefaultApp,
    CoverholderApp,
    ConfirmationModal,
    Loader,
    ActivityMonitor,
  },

  computed: {
    path() {
      return this.$route?.name
    },
  },

  async created() {
    if (localStorage.getItem('auth_token')) {
      if (!store.getters['AuthModule/' + AUTH_USER_GETTER]) {
        await store.dispatch('AuthModule/' + GET_AUTH_USER_ACTION)
        await store.dispatch('SettingModule/' + GET_SETTINGS_ACTION)
      }
    }
  },
}
</script>
