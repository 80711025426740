<template>
  <div
    v-if="uiOptions.visible"
    class="position-relative"
    :class="uiOptions.class"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <SelectField
      :ref="snakeKey"
      v-model="node.value"
      :is-money-select="true"
      :precision="uiOptions.precision"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="node.description"
      :options="node.options"
      :name="name"
      v-bind="$attrs"
      :required="uiOptions.required"
      :readonly="readOnly"
      :placeholder="__(uiOptions.placeholder)"
      :datamask="uiOptions.datamask"
      :allow-empty="false"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :open-direction="node.uiProperties.openDirection"
      :quote-node="node"
      :hint="__(node.uiOptions.hint)"
    >
      <template #append>
        <select
          v-model="currencyModel"
          name="currency_code"
          :class="{
            'ui-currency-select': true,
            'form-control': true,
            readonly: readOnly,
          }"
          :disabled="readOnly"
        >
          <option
            v-for="(option, key) in currencies"
            :key="key"
            :value="option.value"
          >
            {{ option.label.toUpperCase() }}
          </option>
        </select>
      </template>
    </SelectField>
    <slot name="node-actions" />
  </div>
</template>

<script>
import {
  CURRENCIES_GETTER,
  PRIMARY_CURRENCY_GETTER,
} from '@/Modules/Base/SettingModule.js'
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeMoneySelect',

  mixins: [UiTypeMixin],

  data() {
    return {
      currencyModel: null,
    }
  },

  computed: {
    currency() {
      return this.$store.getters[`SettingModule/${PRIMARY_CURRENCY_GETTER}`]
    },

    currencies() {
      return this.$store.getters[`SettingModule/${CURRENCIES_GETTER}`]
    },
  },

  watch: {
    currency: {
      handler(newValue) {
        this.currencyModel = newValue
      },
      immediate: true,
    },
  },
}
</script>
<style>
.ui-currency-select {
  max-width: 70px;
  padding-left: 5px;
  padding-right: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: none;
}
</style>
