<template>
  <div>
    <stack-modal
      :cancel-button="{ visible: false }"
      :modal-class="{ 'modal-sm': true }"
      :save-button="{ visible: false }"
      :show="dialog.show"
    >
      <h2>{{ __('modal.confirmation') }}</h2>

      <p class="text-justify" v-html="dialog.text"></p>

      <div slot="modal-footer" class="modal-footer d-flex justify-content-center py-2">
        <vue-button
          :label="__('buttons.confirm')" custom-class="mr-2 btn-sm btn-danger" data-testid="confirm-btn"
          icon="fe fe-check" @click="confirm"
        />
        <vue-button
          :label="__('buttons.cancel')"
          custom-class="btn-sm btn-secondary"
          data-testid="cancel-btn"
          @click="cancel"
        />
      </div>
    </stack-modal>
  </div>
</template>

<script>
import { CLOSE_CONFIRMATION_DIALOG_ACTION, CONFIRMATION_DIALOG_GETTER } from '@/Modules/Base/BaseModule.js'
import { mapGetters } from 'vuex'
import StackModal from '@innologica/vue-stackable-modal'

export default {
  name: 'ConfirmationModal',

  components: { StackModal },

  inject: [ '$validator' ],

  computed: {
    ...mapGetters('BaseModule', {
      dialog: CONFIRMATION_DIALOG_GETTER
    })
  },

  methods: {
    cancel () {
      const { onCancel } = this.dialog

      this.$store.dispatch(`BaseModule/${ CLOSE_CONFIRMATION_DIALOG_ACTION }`)
        .then(() => onCancel && onCancel())
    },

    confirm () {
      const { onConfirm } = this.dialog

      this.$store.dispatch(`BaseModule/${ CLOSE_CONFIRMATION_DIALOG_ACTION }`)
        .then(() => onConfirm && onConfirm())
    }
  }
}
</script>
