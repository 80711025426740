import ActionPopover from '@/General/ActionPopover.vue'
import Badge from '@/General/Badge.vue'
import Card from '@/General/Card.vue'
import AiPopup from '@/General/AiPopup.vue'
import DataGroup from '@/General/DataGroup/DataGroup.vue'
import DataGroupItem from '@/General/DataGroup/DataGroupItem.vue'
import Flash from '@/General/Flash.vue'
import LookUpField from '@/General/Form/Fields/LookUpField.vue'
import Checkbox from '@/General/Form/Fields/Checkbox.vue'
import Checkboxes from '@/General/Form/Fields/Checkboxes/Checkboxes.vue'
import FileField from '@/General/Form/Fields/FileField.vue'
import JsonField from '@/General/Form/Fields/JsonField.vue'
import MoneyField from '@/General/Form/Fields/MoneyField.vue'
import Radios from '@/General/Form/Fields/Radios.vue'
import SelectField from '@/General/Form/Fields/SelectField.vue'
import TextAreaField from '@/General/Form/Fields/TextAreaField.vue'
import TextField from '@/General/Form/Fields/TextField.vue'
import CurrencyField from '@/General/Form/Fields/CurrencyField.vue'
import Toggler from '@/General/Form/Fields/Toggler.vue'
import CollapseToggler from '@/General/Tables/CollapseToggler.vue'
import VueButton from '@/General/Form/VueButton.vue'
import TraceLabel from '@/General/Form/Components/TraceLabel.vue'
import UiRulesError from '@/General/Form/Components/UiRulesError.vue'
import ErrorLabel from '@/General/Form/Components/ErrorLabel.vue'
import Modal from '@/General/Modal/Modal.vue'
import OnLoading from '@/General/OnLoading.vue'
import PageHeader from '@/General/PageHeader.vue'
import PageNotFound from '@/General/PageNotFound.vue'
import PageTitle from '@/General/PageTitle.vue'
import TooltipTruncate from '@/General/TooltipTruncate.vue'
import ShowMore from '@/General/ShowMore.vue'
import ShowMoreText from '@/General/ShowMoreText.vue'
import FormActions from '@/General/Form/Fields/FormActions.vue'
import CopyButton from '@/General/CopyButton.vue'
import { BarLoader, RiseLoader } from '@saeris/vue-spinners'
import SchemaDesignerNode from '@/Modules/Schema/SchemaDesigner/Components/SchemaDesignerNode/SchemaDesignerNode.vue'
import FieldHint from '@/General/Form/Components/FieldHint.vue'
import DirectoryNode from '@/Modules/Quote/QuoteDocument/Components/DirectoryNode.vue'
import ScrollContainer from '@/General/Modal/ScrollContainer.vue'
import store from '@/Setup/SetupStore'
import _ from 'lodash'
import { SETTINGS_GETTER } from '@/Modules/Base/SettingModule'

export default {
  install(Vue) {
    const setting = function (key, defaultValue) {
      const settings = store.getters[`SettingModule/${SETTINGS_GETTER}`]

      return (
        _.find(settings, (setting) => setting.key === key)?.value ||
        defaultValue
      )
    }
    // General components
    Vue.component('Modal', Modal)
    Vue.component('Card', Card)
    Vue.component('AiPopup', AiPopup)
    Vue.component('Flash', Flash)
    Vue.component('PageTitle', PageTitle)
    Vue.component('PageHeader', PageHeader)
    Vue.component('PageNotFound', PageNotFound)
    Vue.component('Badge', Badge)
    Vue.component('DataGroup', DataGroup)
    Vue.component('DataGroupItem', DataGroupItem)
    Vue.component('ActionPopover', ActionPopover)
    Vue.component('TooltipTruncate', TooltipTruncate)
    Vue.component('ShowMore', ShowMore)
    Vue.component('ShowMoreText', ShowMoreText)
    Vue.component('CopyButton', CopyButton)
    Vue.component('ScrollContainer', ScrollContainer)

    // Form components
    Vue.component('TextAreaField', TextAreaField)

    Vue.component('TextField', TextField)
    Vue.component('CurrencyField', CurrencyField)
    Vue.component('EditorField', () =>
      import(
        /* webpackChunkName: "quill" */ '@/General/Form/Fields/Editor/EditorField.vue'
      )
    )
    Vue.component('FileField', FileField)
    Vue.component('VueButton', VueButton)
    Vue.component('Checkbox', Checkbox)
    Vue.component('Checkboxes', Checkboxes)
    Vue.component('DateField', () =>
      import(
        /* webpackChunkName: "date-picker" */ '@/General/Form/Fields/DateField.vue'
      )
    )
    Vue.component('SelectField', SelectField)
    Vue.component('MoneyField', MoneyField)
    Vue.component('Radios', Radios)
    Vue.component('Toggler', Toggler)
    Vue.component('CollapseToggler', CollapseToggler)
    Vue.component('JsonField', JsonField)
    Vue.component('TraceLabel', TraceLabel)
    Vue.component('UiRulesError', UiRulesError)
    Vue.component('ErrorLabel', ErrorLabel)
    Vue.component('FieldHint', FieldHint)

    Vue.component('OnLoading', OnLoading)
    Vue.component('QuoteFormRenderer', () =>
      import(
        /* webpackChunkName: "quotes" */ '@/Modules/Quote/Components/QuoteForm/QuoteFormRenderer.vue'
      )
    )
    Vue.component('QuoteFormNode', () =>
      setting('ui_lazy_load_quote_form_fields')
        ? import(
            /* webpackChunkName: "quotes" */ '@/Modules/Quote/Components/QuoteForm/QuoteFormNodeLazy.vue'
          )
        : import(
            /* webpackChunkName: "quotes" */ '@/Modules/Quote/Components/QuoteForm/QuoteFormNode.vue'
          )
    )
    Vue.component('LookUpField', LookUpField)
    Vue.component('FormActions', FormActions)

    Vue.component('RiseLoader', RiseLoader)
    Vue.component('BarLoader', BarLoader)
    Vue.component('SchemaDesignerNode', SchemaDesignerNode)

    Vue.component('DirectoryNode', DirectoryNode)
  },
}
