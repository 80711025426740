<template>
  <b-list-group flush class="my-n3">
    <slot/>
  </b-list-group>
</template>

<script>
export default {
  name: 'DataGroup'
}
</script>

<style lang="scss">
 .inline-data-group .list-group-item {
  border-bottom-width: 1px !important;
 }
</style>
