
<div class="d-flex align-items-center justify-content-end actionpopover">
  <b-button
    :id="id"
    size="xs"
    variant="outline-secondary"
    :data-testid="dataTestid"
    class="dropdown-ellipses dropdown-toggle ml-2"
    @click.prevent
  >
    <Icon icon="ellipsis-v" size="18" />
  </b-button>

  <b-popover
    :target="id.toString()"
    triggers="hover click blur"
    :placement="placement"
    :delay="delay"
    :custom-class="customClass"
  >
    <slot />
  </b-popover>
</div>
