<template>
  <div>
    <div :id="getPopoverId">
      <b-button
        v-if="!authUser().photo"
        variant="light"
        class="btn-notification d-flex mr-3 align-items-center"
      >
        <icon icon="user" class="text-muted" :size="iconSize" />
      </b-button>
      <b-avatar v-else button class="mr-3" :src="authUser().photo" />
    </div>

    <b-popover
      :target="getPopoverId"
      triggers="hover"
      placement="bottom"
      custom-class="user-popover"
    >
      <template #title>
        <div class="d-flex justify-content-between align-center pr-1 pl-1">
          <h4 class="mb-0">
            <b-avatar
              v-if="authUser().photo"
              :src="authUser().photo"
              :size="18"
            />
            <Icon v-else icon="user" class="text-muted mr-2" />
            {{ authUser().name }}

            <span class="pl-2">
              <b-badge :variant="authUser().statusData().badge">
                {{ __(authUser().statusData().label) }}
              </b-badge>
            </span>
          </h4>

          <RouterLink :to="{ name: 'profile.show' }" class="small">
            {{ __('users.popover-title') }}
          </RouterLink>
        </div>
      </template>

      <hr />

      <b-list-group flush class="my-n3">
        <b-list-group-item>
          <b-row class="align-items-center">
            <b-col>
              <h5 class="mb-0 text-muted data-item">
                {{ __('users.popover-entity-name') }}
              </h5>
            </b-col>

            <b-col cols="auto">
              {{ authUser().entity.name }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row class="align-items-center">
            <b-col>
              <h5 class="mb-0 text-muted data-item">
                {{ __('users.popover-email-address') }}
              </h5>
            </b-col>

            <b-col cols="auto">
              {{ authUser().email }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row class="align-items-center">
            <b-col>
              <h5 class="mb-0 text-muted data-item">
                {{ __('users.popover-phone-no') }}
              </h5>
            </b-col>

            <b-col cols="auto">
              {{ authUser().phone || '-' }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row class="align-items-center text-right">
            <b-col cols="12">
              <b-button variant="dark" size="sm" @click="logout">
                <icon icon="sign-out-alt" class="mr-2" size="14"></icon>
                {{ __('buttons.logout') }}
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </div>
</template>

<script>
import { RESET_AUTH_USER } from '@/Modules/Auth/AuthModule.js'
import AuthService from '@/Modules/Auth/AuthService.js'
import PopOverMixin from '@/Modules/Base/Mixins/PopOverMixin.js'
import { mapMutations } from 'vuex'

export default {
  name: 'UserPopover',

  mixins: [PopOverMixin],

  computed: {
    getPopoverId: function () {
      return !this.authUser().photo
        ? 'user-popover-button'
        : 'user-photo-popover-button'
    },
  },

  methods: {
    ...mapMutations('AuthModule', {
      resetAuthUser: RESET_AUTH_USER,
    }),

    logout() {
      AuthService.logout().then(() => {
        localStorage.removeItem('auth_token')
        this.resetAuthUser()
        this.$navigate('auth.login')
      })
    },
  },
}
</script>

<style lang="scss">
.user-popover {
  padding: 15px;
  max-width: 350px;
  width: 350px;
}
</style>
