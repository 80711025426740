<template>
  <div class="wrap-text">
    {{ invoice.number }} | {{ invoice.issueDate }} | {{ invoice.client }}
  </div>
</template>
<script>
import SearchRowMixin from '@/Modules/GlobalSearch/Components/SearchRowMixin.js'
import InvoiceModel from '@/Modules/Invoice/InvoiceModel.js'

export default {
  name: 'InvoiceRowComponent',

  mixins: [ SearchRowMixin ],

  data() {
    return {
      invoice: new InvoiceModel(this.model || {})
    }
  }
}
</script>
