
<div>
  <template
    v-if="path && path !== 'auth.redirect-special-role-user' && $authCheck()"
  >
    <CoverholderApp v-if="authUser().isCoverholder()" />
    <DefaultApp v-else />
    <ActivityMonitor />
  </template>

  <template v-else>
    <div
      class="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
      style="height: 100vh"
    >
      <b-container>
        <RouterView />
      </b-container>
    </div>
  </template>

  <Loader />
  <Modal />
  <ConfirmationModal />
  <InformationToast />
</div>
