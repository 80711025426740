<template>
  <b-navbar toggleable="xl" type="light">
    <b-container>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-item-center">
          <RouterLink class="navbar-brand mr-4" :to="{ name: 'home' }">
            <img
              :src="setting('ui_logo')"
              :alt="setting('ui_name')"
              class="navbar-brand-img img-fluid"
            />
          </RouterLink>
          <GlobalSearch
            v-if="globalSearch === 'top-left'"
            v-can:system.global-search
            class="mx-3"
            :style="{ width: '350px' }"
          />
        </div>
        <div class="navbar-user">
          <UserEntityPopover />
          <UserDropdown icon-size="20" />
          <NotificationPopover icon-size="20" />
          <ActivityPopover v-can:activities.view icon-size="20" />
          <CreateQuotePopover v-can:quotes.create icon-size="20" />
          <LanguagePopover
            v-if="setting('ui_menu_language')"
            class="ml-5 mr-3"
          />
        </div>
      </div>
      <div
        class="d-flex align-items-start justify-content-between navbar-sub-nav"
      >
        <b-navbar-toggle target="nav-collapse" />
        <b-collapse id="nav-collapse" is-nav class="order-xl-first">
          <b-navbar-nav>
            <b-nav-item
              :to="{ name: 'home' }"
              :active="$route.name === 'home'"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="estate" />
              <span>{{ __('menu.home') }}</span>
            </b-nav-item>

            <b-nav-item
              v-if="showInbox"
              v-can-any="['inbox.personal.view', 'inbox.company.view']"
              :to="{ name: 'inbox.list' }"
              :active="$route.name === 'inbox.list'"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="inbox" />
              <span>{{ __('menu.inbox') }} </span>
            </b-nav-item>

            <b-nav-item
              v-can:quotes.view
              :to="{ name: 'quotes.list' }"
              :active="$route.name && $route.name.includes('quotes.')"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="file-alt" />
              {{ __('menu.quotes') }}
              <RouterLink
                v-show="quoteStateCount && quoteStateCount > 0"
                v-b-popover.hover.html.bottom="'In Progress'"
                :to="{ name: 'quotes.list', params: { tab: 'in-progress' } }"
                class="badge badge-primary ml-auto"
              >
                {{ quoteStateCount }}
              </RouterLink>
            </b-nav-item>

            <b-nav-item
              v-can:policies.view
              :to="{ name: 'policies.list' }"
              :active="$route.name && $route.name.includes('policies.')"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="umbrella" />
              {{ __('menu.policies') }}
            </b-nav-item>

            <b-nav-item
              v-can-any="[
                'renewals.list.ending-soon',
                'renewals.list.process',
                'renewals.list.overdue',
                'renewals.list.expired',
                'renewals.list.successful',
              ]"
              :to="{ name: 'renewals.list' }"
              :active="$route.name && $route.name.includes('renewals.')"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="share" />
              {{ __('menu.policies-renewals') }}
            </b-nav-item>

            <b-nav-item
              v-can:clients.view
              :to="{ name: 'clients.list' }"
              :active="$route.name && $route.name.includes('clients.')"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="bag" />
              {{ __('menu.clients') }}
            </b-nav-item>

            <b-nav-item-dropdown
              v-can-any="[
                'invoices.view',
                'payments.view',
                'transaction-categories.view',
                'statements.view',
                'payment-accounts.view',
                'payment-plans.view',
                'currencies.view',
                'payment-terms.view',
              ]"
              right
              class="nowrap"
              :toggle-class="financesClassActiveState ? 'active' : ''"
            >
              <template #button-content>
                <Icon class="text-muted mr-1" icon="coins" />
                {{ __('menu.finances') }}
              </template>

              <b-dropdown-item
                v-can:invoices.view
                :to="{ name: 'invoices.list' }"
              >
                <Icon class="text-muted mr-3" icon="invoice" />
                {{ __('menu.invoices') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:payments.view
                :to="{ name: 'payments.list' }"
              >
                <Icon class="text-muted mr-3" icon="transaction" />
                {{ __('menu.payments') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:transaction-categories.view
                :to="{ name: 'transaction-categories.list' }"
              >
                <Icon class="text-muted mr-3" icon="newspaper" />
                {{ __('menu.transaction-categories') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:statements.view
                :to="{ name: 'statements.agent-list' }"
              >
                <Icon class="text-muted mr-3" icon="university" />
                {{ __('menu.statements') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:payment-accounts.view
                :to="{ name: 'payment-accounts.list' }"
              >
                <Icon class="text-muted mr-3" icon="credit-card" />
                {{ __('payment-accounts.payment-accounts') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:payment-plans.view
                :to="{ name: 'payment-plans.list' }"
              >
                <Icon class="text-muted mr-3" icon="file-plus-alt" />
                {{ __('payment-plans.pre-title') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:currencies.view
                :to="{ name: 'currencies.list' }"
              >
                <Icon class="text-muted mr-3" icon="usd-circle" />
                {{ __('menu.currencies') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:payment-terms.view
                :to="{ name: 'payment-terms.list' }"
              >
                <Icon class="text-muted mr-3" icon="wallet" />
                {{ __('menu.payment-terms') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown
              v-can-any="[
                'schemas.view',
                'binders.view',
                'workflows.view',
                'datasets.view',
                'endorsement-libraries.view',
                'products.view',
              ]"
              right
              class="nowrap"
              :toggle-class="underwritingClassActiveState ? 'active' : ''"
            >
              <template #button-content>
                <Icon class="text-muted mr-1" icon="edit-alt" />
                {{ __('menu.underwriting') }}
              </template>

              <b-dropdown-item
                v-can:schemas.view
                :to="{ name: 'schemas.list' }"
              >
                <Icon class="text-muted mr-3" icon="web-grid" />
                {{ __('menu.schemas') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:binders.view
                :to="{ name: 'binders.list' }"
              >
                <Icon class="text-muted mr-3" icon="book-open" />
                {{ __('menu.binders') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:workflows.view
                :to="{ name: 'workflows.list' }"
              >
                <Icon class="text-muted mr-3" icon="arrows-left-down" />
                {{ __('menu.workflows') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:datasets.view
                :to="{ name: 'datasets.list' }"
              >
                <Icon class="text-muted mr-3" icon="list-ul" />
                {{ __('menu.datasets') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:endorsement-libraries.view
                :to="{ name: 'endorsement-libraries.list' }"
              >
                <Icon class="text-muted mr-3" icon="archive" />
                {{ __('menu.endorsements') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:products.view
                :to="{ name: 'products.list' }"
              >
                <Icon class="text-muted mr-3" icon="box" />
                {{ __('menu.products') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown
              v-can-any="[
                'entities.view',
                'users.view',
                'roles.view',
                'permissions.view',
                'quotes.object-groups.view',
                'email-accounts.view',
              ]"
              right
              class="nowrap"
              :toggle-class="adminClassActiveState ? 'active' : ''"
            >
              <template #button-content>
                <Icon class="text-muted mr-1" icon="setting" />
                {{ __('menu.admin') }}
              </template>

              <b-dropdown-item
                v-can:entities.view
                :to="{ name: 'entities.list' }"
              >
                <Icon class="text-muted mr-3" icon="briefcase" />
                {{ __('menu.entities') }}
              </b-dropdown-item>

              <b-dropdown-item v-can:users.view :to="{ name: 'users.list' }">
                <Icon class="text-muted mr-3" icon="user" />
                {{ __('menu.users') }}
              </b-dropdown-item>

              <b-dropdown-item v-can:roles.view :to="{ name: 'roles.list' }">
                <Icon class="text-muted mr-3" icon="shield" />
                {{ __('menu.roles') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:permissions.view
                :to="{ name: 'permissions.list' }"
              >
                <Icon class="text-muted mr-3" icon="tag" />
                {{ __('menu.permissions') }}
              </b-dropdown-item>

              <b-dropdown-item v-is-supervisor :to="{ name: 'settings.list' }">
                <Icon class="text-muted mr-3" icon="setting" />
                {{ __('menu.settings') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:quotes.object-groups.view
                :to="{ name: 'quote-object-groups.list' }"
              >
                <Icon class="text-muted mr-3" icon="bookmark" />
                {{ __('menu.object-groups') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:document-templates.view
                :to="{ name: 'document-templates.list' }"
              >
                <Icon class="text-muted mr-3" icon="file-plus-alt" />
                {{ __('menu.document-templates') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:email-accounts.view
                :to="{ name: 'email-accounts.list' }"
              >
                <Icon class="text-muted mr-3" icon="envelope-share" />
                {{ __('menu.email-accounts') }}
              </b-dropdown-item>

              <b-dropdown-item
                v-can:scheduled-tasks.view
                :to="{ name: 'scheduled-tasks.list' }"
              >
                <Icon class="text-muted mr-3" icon="schedule" />
                {{ __('menu.scheduled-tasks') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item
              v-can:reports.view
              :to="{ name: 'reports.list' }"
              :active="$route.name && $route.name.includes('reports.')"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="chart-bar" />
              {{ __('menu.reports') }}
            </b-nav-item>

            <b-nav-item
              v-can:videos.view
              to="/videos"
              :active="$route.name && $route.name.includes('videos.')"
              class="nowrap"
            >
              <Icon class="text-muted mr-1" icon="video-question" />
              {{ tc('menu.help-video', 2) }}
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <GlobalSearch
          v-if="globalSearch === 'bottom-right'"
          v-can:system.global-search
          class="mx-3"
          :style="{ width: '350px' }"
        />
      </div>
    </b-container>
  </b-navbar>
</template>

<script>
import {
  GET_QUOTE_STATE_COUNTER_ACTION,
  QUOTE_STATE_COUNTER_GETTER,
} from '@/Modules/Base/BaseModule.js'
import ActivityPopover from '@/Modules/Base/Components/ActivityPopover.vue'
import CreateQuotePopover from '@/Modules/Base/Components/CreateQuotePopover.vue'
import NotificationPopover from '@/Modules/Base/Components/NotificationPopover.vue'
import UserEntityPopover from '@/Modules/Base/Components/UserEntityPopover.vue'
import UserDropdown from '@/Modules/Base/Components/UserPopover.vue'
import { mapActions, mapGetters } from 'vuex'
import LanguagePopover from '@/Modules/Base/Components/LanguagePopover.vue'
import GlobalSearch from '@/Modules/GlobalSearch/Components/GlobalSearch.vue'
import { AUTH_USER_GETTER } from '@/Modules/Auth/AuthModule.js'
import store from '@/Setup/SetupStore.js'
import { UserEmailAccountStatus } from '@/Modules/User/UserEmailAccount/UserEmailAccountModel.js'

export const MEDIA_BREAKPOINT_XL = 1200

export default {
  name: 'DefaultHeader',

  components: {
    GlobalSearch,
    CreateQuotePopover,
    ActivityPopover,
    NotificationPopover,
    UserDropdown,
    UserEntityPopover,
    LanguagePopover,
  },

  data() {
    return {
      windowWidth: 0,
      globalSearch: 'bottom-right',
    }
  },

  computed: {
    ...mapGetters('BaseModule', {
      quoteStateCount: QUOTE_STATE_COUNTER_GETTER,
    }),

    adminClassActiveState: function () {
      if (!this.$route.name) return false

      return (
        this.$route.name.includes('entities.') ||
        this.$route.name.includes('users.') ||
        this.$route.name.includes('roles.') ||
        this.$route.name.includes('permissions.') ||
        this.$route.name.includes('quote-object-groups.')
      )
    },

    underwritingClassActiveState: function () {
      if (!this.$route.name) return false

      return (
        this.$route.name.startsWith('schemas.') ||
        this.$route.name.startsWith('binders.') ||
        this.$route.name.startsWith('workflows.') ||
        this.$route.name.startsWith('datasets.') ||
        this.$route.name.startsWith('endorsements.') ||
        this.$route.name.startsWith('products.')
      )
    },

    financesClassActiveState: function () {
      if (!this.$route.name) return false

      return (
        this.$route.name.startsWith('invoices.') ||
        this.$route.name.startsWith('payments.') ||
        this.$route.name.includes('transaction-categories.') ||
        this.$route.name.includes('statements.') ||
        this.$route.name.includes('payment-accounts.') ||
        this.$route.name.includes('payment-plans.') ||
        this.$route.name.includes('currencies.') ||
        this.$route.name.includes('payment-terms.')
      )
    },
  },

  computed: {
    showInbox() {
      const user = store.getters['AuthModule/' + AUTH_USER_GETTER]
      return (
        user.emailAccounts?.filter(
          (account) => account.status === UserEmailAccountStatus.ACTIVE
        ).length > 0
      )
    },
  },

  watch: {
    windowWidth: {
      handler(newValue) {
        if (newValue > MEDIA_BREAKPOINT_XL) {
          this.globalSearch = 'bottom-right'
          return
        }
        this.globalSearch = 'top-left'
      },
    },
  },

  created() {
    this.getQuoteStateCounter('inProgress')
    this.handleWindowResize = _.debounce((event) => {
      this.windowWidth = event.target.innerWidth
    }, 300)
  },

  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.handleWindowResize)
  },

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    ...mapActions('BaseModule', {
      getQuoteStateCounter: GET_QUOTE_STATE_COUNTER_ACTION,
    }),
  },
}
</script>
<style scoped lang="scss">
.container {
  flex-direction: column;
  align-items: stretch;

  .navbar-collapse {
    flex-grow: 0;
    margin-left: 0;

    &::before {
      margin: 0;
    }
  }

  .navbar-sub-nav {
    margin-top: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    .navbar-collapse {
      padding-left: 0;
    }

    .navbar-sub-nav {
      margin-top: 0;
    }
  }
}
</style>
<style>
.text-muted {
  color: #b0bfd7 !important;
}

.btn-notification {
  padding-left: 11px;
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link.active:before {
    border-bottom: 2px solid #2c7be5;
  }
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.05rem rgb(132 150 177 / 50%);
}
</style>
