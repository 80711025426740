
<div class="alert alert-danger alert-dismissible fade show" role="alert">
  <slot>
    <strong>{{ label }}</strong> {{ description }}
  </slot>

  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">×</span>
  </button>
</div>
