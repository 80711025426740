
<b-toast
  v-model="informationToast.show"
  :variant="informationToast.variant"
  :title="informationToast.title"
  auto-hide-delay="5000"
  @hide="hideToast"
>
  {{ informationToast.text }}
</b-toast>
