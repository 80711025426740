<template>
  <b-list-group-item>
    <b-row align-content="center">
      <b-col v-if="collapsable" cols="auto d-flex align-items-center pr-0">
        <slot name="collapsable" />
      </b-col>
      <b-col v-if="selectable" class="col-checkbox" cols="auto flex">
        <slot name="checkbox" />
      </b-col>
      <b-col cols="auto">
        <slot name="iconBlock">
          <div class="avatar avatar-sm">
            <div
              class="avatar-title font-size-lg rounded-circle text-primary"
              style="
                background-color: #f9fbfd;
                border: 1px solid rgb(227, 235, 246);
              "
            >
              <slot name="icon" />
            </div>
          </div>
        </slot>
      </b-col>

      <b-col class="ml-n2 d-flex align-items-center">
        <div class="w-100">
          <h4 class="mb-1 label">
            <slot name="label" />
            <slot name="badge" />
          </h4>
          <p class="small mb-0 description">
            <slot name="description" />
          </p>
        </div>
      </b-col>

      <b-col cols="auto">
        <slot name="documentType" />
      </b-col>

      <slot name="default" />

      <div class="mr-3 d-flex align-items-start">
        <slot name="actionButtons" />
      </div>
    </b-row>

    <slot name="afterItem" />
    <slot name="childItem" />
  </b-list-group-item>
</template>
  
  <script>
export default {
  name: 'DirectoryListItemTemplate',

  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
  
<style lang="scss" scoped>
.col-checkbox {
  width: 35px;
  display: flex;
  align-items: center;
}
</style>
