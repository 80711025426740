<template>
  <div
    class="position-relative col-auto"
    :class="{ [uiOptions.class]: true }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <SelectField
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="__(node.description)"
      :options="node.options"
      :quote-node="node"
      :name="name"
      v-bind="$attrs"
      :required="uiOptions.required"
      :readonly="readOnly"
      :placeholder="uiOptions.placeholder"
      :datamask="uiOptions.datamask"
      :multiple="uiOptions.multiple"
      :allow-empty="allowEmpty"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :tooltip-class="node.uiProperties.tooltipClass"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeSelectMixin from '@/Modules/Quote/Components/QuoteForm/Elements/Mixins/UiTypeSelectMixin.js'
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeSelect',

  mixins: [UiTypeMixin, UiTypeSelectMixin],
}
</script>
