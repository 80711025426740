<template>
  <div class="d-flex align-items-center wrap-text">
    <span>{{ policy.number }} |</span>
    <span v-if="policy.status" :class="policy.status.class" class="badge mx-2">
      {{ __(policy.status.name) }}
    </span>
    <span class="ml-0 mr-2"
      >| {{ policy.inceptionDate }} - {{ policy.expiryDate }}</span
    >
    <span>| {{ policy.client }}</span>
  </div>
</template>
<script>
import SearchRowMixin from '@/Modules/GlobalSearch/Components/SearchRowMixin.js'
import PolicyModel from '@/Modules/Quote/Policy/PolicyModel.js'

export default {
  name: 'PolicyRowComponent',

  mixins: [SearchRowMixin],

  data() {
    return {
      policy: new PolicyModel(this.model || {}),
    }
  },
}
</script>
