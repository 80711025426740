<template>
  <div class="wrap-text">
    <span v-if="isTypeIndividual">
      {{ client.firstName }} {{ client.lastName }} | {{ client.code }}
    </span>
    <span v-else>
      {{ client.legalName }} | {{ client.code }}
    </span>
  </div>
</template>
<script>
import SearchRowMixin from '@/Modules/GlobalSearch/Components/SearchRowMixin.js'
import ClientModel, { clientType } from '@/Modules/Client/ClientModel.js'

export default {
  name: 'ClientRowComponent',

  mixins: [ SearchRowMixin ],

  data() {
    return {
      client: new ClientModel(this.model || {})
    }
  },

  computed: {
    isTypeIndividual() {
      return this.client.type === clientType.individual
    }
  }
}
</script>
