
<div
  v-if="groups"
  class="global-search-dropdown shadow"
  data-testid="search-dropdown"
  tabindex="0"
>
  <div
    v-for="(items, group) in groups"
    :key="group"
    class="item-block border-top py-2"
  >
    <div class="px-3 font-weight-bold d-flex align-items-center">
      <Icon
        v-if="GlobalSearchUtilities.dataByType(group)"
        :icon="GlobalSearchUtilities.dataByType(group).icon"
        class="mr-2"
        size="18"
      />
      {{ lodash.upperFirst(group) }}
    </div>
    <b-dropdown-item
      v-for="item in items"
      :key="item.searchable.id"
      :active="item.active"
      @click="$emit('dropdownItemClick', group, item)"
    >
      <component
        :is="GlobalSearchUtilities.dataByType(group).component"
        v-if="GlobalSearchUtilities.dataByType(group)"
        :model="item.searchable"
      />
    </b-dropdown-item>
  </div>
</div>
