
<div>
  <stack-modal
    :cancel-button="{ visible: false }"
    :modal-class="{ 'modal-sm': true }"
    :save-button="{ visible: false }"
    :show="dialog.show"
  >
    <h2>{{ __('modal.confirmation') }}</h2>

    <p class="text-justify" v-html="dialog.text"></p>

    <div slot="modal-footer" class="modal-footer d-flex justify-content-center py-2">
      <vue-button
        :label="__('buttons.confirm')" custom-class="mr-2 btn-sm btn-danger" data-testid="confirm-btn"
        icon="fe fe-check" @click="confirm"
      />
      <vue-button
        :label="__('buttons.cancel')"
        custom-class="btn-sm btn-secondary"
        data-testid="cancel-btn"
        @click="cancel"
      />
    </div>
  </stack-modal>
</div>
