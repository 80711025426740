
<div class="position-relative col-padding">
  <Checkboxes
    :ref="snakeKey"
    v-model="node.value"
    :visible="uiOptions.visible"
    :readonly="readOnly"
    :custom-class="uiOptions.class"
    :inline-control="node.uiProperties.inlineControl"
    :label="__(node.label)"
    :description="__(node.description)"
    :options="node.options"
    :quote-node="node"
    :name="name"
  />
  <slot name="node-actions" />
</div>
