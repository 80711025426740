<template>
  <div
    class="position-relative col-auto"
    :class="{ [uiOptions.class]: true }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <DateField
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="node.label"
      :description="node.description"
      :name="name"
      :required="uiOptions.required"
      :regex="uiOptions.regex"
      :readonly="readOnly"
      :placeholder="uiOptions.placeholder"
      :datamask="uiOptions.datamask"
      :quote-node="node"
      :show-time="uiOptions.showTime"
      :min-date="uiOptions.minDate"
      :inline="node.uiProperties.inline"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :tooltip-class="node.uiProperties.tooltipClass"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeDate',

  mixins: [UiTypeMixin],
}
</script>
