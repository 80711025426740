import UserRowComponent from '@/Modules/GlobalSearch/RowComponents/UserRowComponent.vue'
import ClientRowComponent from '@/Modules/GlobalSearch/RowComponents/ClientRowComponent.vue'
import QuoteRowComponent from '@/Modules/GlobalSearch/RowComponents/QuoteRowComponent.vue'
import PolicyRowComponent from '@/Modules/GlobalSearch/RowComponents/PolicyRowComponent.vue'
import EntityRowComponent from '@/Modules/GlobalSearch/RowComponents/EntityRowComponent.vue'
import InvoiceRowComponent from '@/Modules/GlobalSearch/RowComponents/InvoiceRowComponent.vue'

export const searchType = Object.freeze({
  users: 'users',
  clients: 'clients',
  quotes: 'quotes',
  policies: 'policies',
  entities: 'entities',
  invoices: 'invoices',
})

export default class GlobalSearchUtilities {
  static dataByType(type, item = null) {
    switch (type) {
      case searchType.users:
        return {
          icon: 'user',
          component: UserRowComponent,
          url: { name: 'users.show', params: { userId: item?.id } },
        }
      case searchType.clients:
        return {
          icon: 'bag',
          component: ClientRowComponent,
          url: { name: 'clients.show', params: { clientId: item?.id } },
        }
      case searchType.quotes:
        return {
          icon: 'file',
          component: QuoteRowComponent,
          url: { name: 'quotes.show', params: { quoteId: item?.id } },
        }
      case searchType.policies:
        return {
          icon: 'umbrella',
          component: PolicyRowComponent,
          url: { name: 'policies.show', params: { quoteId: item?.id } },
        }
      case searchType.entities:
        return {
          icon: 'briefcase',
          component: EntityRowComponent,
          url: { name: 'entities.show', params: { entityId: item?.id } },
        }
      case searchType.invoices:
        return {
          icon: 'invoice',
          component: InvoiceRowComponent,
          url: { name: 'invoices.show', params: { invoiceId: item?.id } },
        }
    }
  }
}
