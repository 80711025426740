<template>
  <b-col :cols="cols">
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button :disabled="disabledSubmit" size="sm" class="mr-2" variant="primary" @click="onSubmit()">
          <Icon icon="save" class="mr-2" size="14" />
          {{ __('buttons.save') }}
        </b-button>

        <b-button size="sm" variant="outline-primary" @click="onCancel()">
          <Icon icon="times" class="mr-2" size="14" />
          {{ __('buttons.cancel') }}
        </b-button>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: 'FormActions',

  props: {
    cols: {
      type: String,
      default: '12',
    },
    onSubmit: {
      type: Function,
      default: () => '',
    },
    onCancel: {
      type: Function,
      default: () => '',
    },
    disabledSubmit: {
      type: Boolean,
      default: () => false,
    }
  },
}
</script>
