
<div>
  <b-button
    class="btn-notification dropdown-toggle mr-3 d-none d-lg-flex align-items-center"
    :to="{ name: 'activities.list' }"
  >
    <icon icon="schedule" class="text-muted" :size="iconSize"></icon>

    <b-badge
      v-if="authUser().overdueActivitiesCount"
      variant="secondary"
      class="badge-notification"
    >
      {{ applyCompactNotation(authUser().overdueActivitiesCount) }}
    </b-badge>
  </b-button>
</div>
