
<div v-if="isPreview && customCssEnabled" class="position-fixed preview-div">
  <b-alert
    :show="isPreview"
    variant="warning"
    class="d-flex align-items-center justify-content-between rounded-0"
    :style="{ height: '50px' }"
  >
    <div>{{ __('custom-css.preview-mode-text') }}</div>
    <b-button variant="dark" size="sm" @click="exitPreview">
      {{ __('custom-css.exit-preview-mode') }}
    </b-button>
  </b-alert>
</div>
