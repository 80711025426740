<template>
  <div
    class="position-relative col-auto"
    :class="{ [uiOptions.class]: true }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <TextField
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="__(node.description)"
      :icon="uiOptions.icon"
      type="text"
      :name="name"
      :quote-node="node"
      :regex="uiOptions.regex"
      :required="uiOptions.required"
      :readonly="readOnly"
      :placeholder="__(uiOptions.placeholder)"
      :datamask="uiOptions.datamask"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :tooltip-class="node.uiProperties.tooltipClass"
      :input-class="node.uiProperties.inputClass"
      :hint="__(node.uiOptions.hint)"
      v-bind="$attrs"
    >
      <template #append>
        <div
          v-b-tooltip.click.v-danger
          :title="tooltip"
          class="input-group-text p-0"
        >
          <a class="link" :href="node.value">
            <Icon icon="external-link-alt" size="14" />
          </a>
        </div>
      </template>
    </TextField>
    <slot name="node-actions" />
  </div>
</template>

<script>
import TraceModeMixin from '@/General/Form/Mixins/TraceModeMixin.js'
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeLink',

  mixins: [UiTypeMixin, TraceModeMixin],

  data() {
    return {
      tooltip: '',
    }
  },
}
</script>
<style scoped lang="scss">
.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;

  &.active {
    a {
      color: #2c7be5;

      &:hover {
        i {
          color: #2c7be5;
        }
      }
    }
  }

  a {
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #95aac9;

    &:hover {
      color: #95aac9;

      i {
        color: #95aac9;
      }
    }
  }
}
</style>
