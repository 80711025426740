import { GET_AUTH_USER_ACTION } from '@/Modules/Auth/AuthModule.js'
import { GET_SETTINGS_ACTION } from '@/Modules/Base/SettingModule.js'
import store from '@/Setup/SetupStore.js'

const setupAppData = async () => {
  if (localStorage.getItem('auth_token')) {
    await store.dispatch('AuthModule/' + GET_AUTH_USER_ACTION)
    await store.dispatch('SettingModule/' + GET_SETTINGS_ACTION)
  }
}

export default setupAppData
