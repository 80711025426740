
<div class="list-group-item-wrapper">
  <DirectoryListItemTemplate
    :style="directoryStyle"
    :item="{ item }"
    collapsable
  >
    <template v-if="showCollapse" #collapsable>
      <div
        v-b-toggle="collapseId"
        class="cursor-pointer"
        @click="handleExpandCollapseClick(item)"
      >
        <Icon v-if="expanded" icon="angle-down" size="14" />
        <Icon v-else icon="angle-right" size="14" />
      </div>
    </template>
    <template #iconBlock>
      <img :src="getFileTypeIcon(item)" :alt="item.name" width="48" />
    </template>
    <template #label>
      <a
        v-can:quotes.documents.view
        href="#"
        @click.prevent="handleExpandFolderClick(item)"
      >
        {{ item.name }}
      </a>
    </template>
    <template #badge>
      <DirectoryBadge :item="item" />
    </template>
    <template #description>
      <DocumentRowSummary v-if="item" :document="item" />
    </template>
    <template #actionButtons>
      <ActionPopover
        :id="'directory-' + item.id"
        v-can-any="['documents.update', 'documents.destroy']"
      >
        <b-button
          v-can:documents.update
          class="dropdown-item"
          size="sm"
          @click="handleEditFolderClick(item)"
        >
          <Icon icon="edit" class="mr-2" size="14" />
          {{ __('buttons.edit') }}
        </b-button>
        <b-button
          v-can:documents.destroy
          class="dropdown-item"
          size="sm"
          @click="removeFolder(item.id)"
        >
          <Icon icon="trash" class="mr-2" size="14" />
          {{ __('buttons.delete') }}
        </b-button>
        <b-button
          v-can:quotes.documents.view
          class="dropdown-item"
          size="sm"
          @click="handleOpenFolderClick(item)"
        >
          <Icon icon="link" class="mr-2" size="14" />
          {{ __('common.open') }}
        </b-button>
      </ActionPopover>
    </template>
  </DirectoryListItemTemplate>
  <b-collapse :id="collapseId" v-model="expanded">
    <DirectoryNode v-for="node in item.nodes" :key="node.id" :item="node" />
  </b-collapse>
</div>
