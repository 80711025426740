<template>
  <div class="d-flex align-items-center wrap-text">
    <span>{{ null !== entity.legalName ? entity.legalName : entity.name }}</span>
    <Badge :variant="entity.typeData().badge" class="ml-2">
      {{ entity.typeData().short }}
    </Badge>
  </div>
</template>
<script>
import SearchRowMixin from '@/Modules/GlobalSearch/Components/SearchRowMixin.js'
import EntityModel from '@/Modules/Entity/EntityModel.js'

export default {
  name: 'EntityRowComponent',

  mixins: [ SearchRowMixin ],

  data() {
    return {
      entity: new EntityModel(this.model || {})
    }
  }
}
</script>
