
<div
  class="position-relative col-auto"
  :class="{ [uiOptions.class]: true }"
  :draggable="isDraggable"
  @dragstart="(event) => $emit('drag-start', event)"
  @dragend="(event) => $emit('drag-end', event)"
>
  <TextField
    :ref="snakeKey"
    v-model="node.value"
    :visible="uiOptions.visible"
    :label="__(node.label)"
    :description="__(node.description)"
    :quote-node="node"
    :icon="uiOptions.icon"
    :min="uiOptions.min"
    :max="uiOptions.max"
    :name="name"
    :required="uiOptions.required"
    :readonly="true"
    :placeholder="__(uiOptions.placeholder)"
    :datamask="uiOptions.datamask"
    :highlight="node.uiProperties.highlight"
    :tooltip="node.uiProperties.tooltip"
    :hint="__(node.uiOptions.hint)"
  >
    <template v-if="node.value && !readOnly" #append>
      <b-button variant="primary">
        <Icon icon="edit" size="16" />
      </b-button>
    </template>
  </TextField>
  <slot name="node-actions" />
</div>
