
<b-list-group-item class="text-reset">
  <b-row :class="{ [rowClass]: !!rowClass }">
    <b-col>
      <small class="text-muted">
        {{ label }}
      </small>
    </b-col>

    <b-col :cols="newLine ? '12' : 'auto'">
      <slot />
      <pre v-if="html" v-html="html" />
      <div v-else v-text="value" />
    </b-col>
  </b-row>
</b-list-group-item>
