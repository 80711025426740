<template>
  <div>
    <Slideout
      ref="slideout"
      :visible="true"
      dock="bottom"
      :title="'AI Assistant'"
      :show-close="false"
      :show-mask="false"
      :close-on-mask-click="false"
      :resizable="expanded"
      :allow-resize="expanded"
      :ignore-esc="true"
      append-to="body"
      @close="hide"
      @resize="onResize"
    >
      <template #btn>
        <b-row class="align-items-center">
          <b-col>
            <b-checkbox
              v-model="moveFiles"
              style="width: 200px"
              @change="setMoveFilesMeta"
            >
              {{ __('aiAssistant.checkbox.move-files') }}
            </b-checkbox>
          </b-col>
          <b-col>
            <b-button
              size="sm"
              variant="primary"
              :disabled="attachments.length === 0"
              @click="extract"
            >
              {{ __('aiAssistant.buttons.extract') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button size="sm" variant="outline-primary" @click="fetchFields">
              {{ __('aiAssistant.buttons.reload') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-if="expanded"
              size="sm"
              variant="link"
              @click="collapse"
            >
              <Icon class="grey ml-2" icon="angle-down" />
            </b-button>
            <b-button v-else size="sm" variant="link" @click="expand">
              <Icon class="grey ml-2" icon="angle-up" />
            </b-button>
          </b-col>
          <b-col>
            <b-button variant="link" @click="hide">
              <Icon class="grey ml-2" icon="times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <div :class="isExpanded">
        <b-card-group class="h-100">
          <b-card class="col-3 pl-2 rounded-0" body-class="pl-0 pr-3 pt-3">
            <b-card-title>
              <b-row class="justify-content-between align-items-center">
                <b-col> Select file </b-col>
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="openUploadModal"
                  >
                    <Icon icon="cloud-upload" size="14" class="mr-2" />
                    {{ __('buttons.upload') }}
                  </b-button>
                  <input type="file" class="d-none" @change="uploadFile" />
                </b-col>
              </b-row>
            </b-card-title>
            <div class="ml-2 text-left">
              <div
                v-for="attachment in attachments"
                :key="attachment.current_filename"
                class="mb-2"
              >
                <b-form-radio
                  v-model="selectedAttachment"
                  :value="attachment.current_filename"
                >
                  <b-row
                    class="align-items-center justify-content-between"
                    style="margin-top: -5px"
                  >
                    <b-col>
                      <img
                        :id="`attachment-icon-${attachment.current_filename}`"
                        :key="`attachment-icon-${attachment.current_filename}`"
                        width="24"
                        :src="getFileTypeIcon(attachment.current_filename)"
                        class="mr-2 cursor-pointer"
                        :alt="attachment.current_filename"
                      />

                      {{ attachment.current_filename }}
                    </b-col>
                    <b-col class="col-1">
                      <b-button
                        size="sm"
                        variant="link"
                        class="m-0 p-0"
                        @click.prevent="preview(attachment)"
                      >
                        <Icon class="grey" icon="external-link-alt" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-radio>
              </div>
            </div>
          </b-card>

          <b-card class="pl-2 rounded-0" body-class="pl-0 pr-3 pt-3">
            <b-card-title>Edit Prompts</b-card-title>
            <b-table
              hover
              :items="items"
              :fields="fields"
              small
              borderless
              @row-clicked="onRowClicked"
            >
              <template #cell(selected)="row">
                <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
              </template>
              <template #cell(key)="row">
                <span>{{ row.item.key }} ({{ row.item.path }})</span>
              </template>
              <template #cell(prompt)="row">
                <b-input v-model="row.item.prompt" />
              </template>
              <template #cell(value)="row">
                <b-row class="justify-content-between align-items-center">
                  <b-col class="col-11">
                    <input
                      v-model="row.item.value"
                      disabled
                      :class="{
                        'form-control': true,
                        readonly: true,
                      }"
                    />
                  </b-col>
                  <b-col class="col-1">
                    <b-button
                      size="sm"
                      variant="link"
                      class="m-0 p-0"
                      @click="scrollToElement(row.item.path)"
                    >
                      <Icon class="grey" icon="crosshair" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-card>
        </b-card-group>
      </div>
    </Slideout>
  </div>
</template>

<script>
import Slideout from '@hyjiacan/vue-slideout'
import '@hyjiacan/vue-slideout/lib/slideout.css'
import FileManager from '@/General/FileManager.js'
import _, { debounce } from 'lodash'
import {
  BASE_NODE_GETTER,
  SCHEMA_GETTER,
  SET_BASE_NODE_PROPERTY,
  SET_META_PROPERTY,
} from '@/Modules/Quote/Components/QuoteForm/QuoteFormModule.js'
import { mapGetters, mapMutations } from 'vuex'
import SchemaService from '@/Modules/Schema/SchemaService.js'
import TemporaryDocumentService from '@/Modules/TemporaryDocument/TemporaryDocumentService.js'

export default {
  name: 'AiPopup',

  components: {
    Slideout,
  },

  data() {
    return {
      temporaryFolderId: null,
      maxHeight: 400,
      fields: [
        { key: 'selected', label: '', class: 'col-1' },
        { key: 'key', label: 'Field', class: 'text-left' },
        { key: 'prompt', label: 'Prompt', class: 'text-left' },
        { key: 'value', label: 'Value', class: 'text-left' },
      ],
      items: [],
      expanded: true,
      attachments: [],
      selectedAttachment: null,
      limitToFields: [],
      moveFiles: false,
    }
  },

  computed: {
    isExpanded() {
      return this.expanded ? 'h-100' : 'd-none'
    },
  },

  watch: {
    '$route.params.temporaryFolderId': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.temporaryFolderId = newValue

          this.setMetaProperty({
            node: 'aiAssistant',
            property: 'caseUUID',
            value: newValue,
          })
        }
      },
    },
  },

  methods: {
    ...mapGetters('QuoteFormModule', {
      baseNode: BASE_NODE_GETTER,
      schema: SCHEMA_GETTER,
    }),
    ...mapMutations('QuoteFormModule', {
      setBaseNodeProperty: SET_BASE_NODE_PROPERTY,
      setMetaProperty: SET_META_PROPERTY,
    }),
    show({ limitToFields, moveFiles }) {
      this.$refs.slideout.setVisibleValue(true)
      this.limitToFields = limitToFields
      this.moveFiles = moveFiles
      this.copyWidthAndPosition()
      this.fetchFields()
      this.fetchFiles()
      this.setMoveFilesMeta()
    },
    hide() {
      this.$refs.slideout.setVisibleValue(false)
    },
    copyWidthAndPosition() {
      const element = document.querySelector('#quoteFormRenderer')

      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach(() => {
          const rect = element.getBoundingClientRect()

          const target = document.querySelector('.vue-slideout-layout')
          target.style.width = `${rect.width}px`
          target.style.left = `${rect.x}px`
        })
      })

      resizeObserver.observe(element)
    },
    expand() {
      this.expanded = true
      document.querySelector(
        '.vue-slideout-layout'
      ).style.height = `${this.maxHeight}px`
    },
    collapse() {
      this.expanded = false
      document.querySelector('.vue-slideout-layout').style.height = '50px'
    },
    getFileTypeIcon(name) {
      return FileManager.getFileTypeIcon(name)
    },
    async download(attachment) {
      const name = attachment.current_filename
      const file = await TemporaryDocumentService.download(
        this.temporaryFolderId,
        name
      )
      if (!file) return
      FileManager.downloadFile(file)
    },
    async preview(attachment) {
      const name = attachment.current_filename
      const extension = name.split('.').pop()
      if (attachment && extension !== 'pdf') {
        this.download(attachment)
        return
      }
      let file = null
      file = await TemporaryDocumentService.download(
        this.temporaryFolderId,
        name
      )
      if (!file) return

      const contentType = _.get(file.headers, 'content-type')
      const url = URL.createObjectURL(
        new Blob([file.data], { type: contentType })
      )

      let pdfWindow = window.open()
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" + url + "'></iframe>"
      )
    },
    onRowClicked(item) {
      item.selected = !item.selected
    },
    extract() {
      const selectedItems = this.items.filter((item) => item.selected)
      selectedItems.map((item) => (item.value = '04-02-2021'))
      this.highlightFields(selectedItems)
    },
    highlightFields(fields) {
      for (const field of fields) {
        const fieldProperty = field.path.replace(/\./g, '.properties.')
        this.setBaseNodeProperty({
          property: `${fieldProperty}.value`,
          value: field.value,
        })
        this.setBaseNodeProperty({
          property: `${fieldProperty}.uiProperties.highlight`,
          value: 'info',
        })
      }
    },
    onResize: debounce(function (event) {
      this.maxHeight = event.size
    }, 200),
    pathToPropertyPath(path) {
      return path.replace(/\./g, '.properties.')
    },
    findProperty(path) {
      const fieldProperty = this.pathToPropertyPath(path)
      return _.get(this.baseNode().properties, fieldProperty)
    },
    scrollToElement(path) {
      const property = this.findProperty(path)
      const element = document.getElementById(property.dataKey)
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      const addInfo = element?.classList.contains('is-info')
      element?.classList.add('is-warning')
      element?.classList.remove('is-info')

      setTimeout(() => {
        element?.classList.remove('is-warning')
        if (addInfo) {
          element?.classList.add('is-info')
        }
      }, 2000)
    },
    fetchFields() {
      const schemaId = this.schema().id
      SchemaService.getAiPrompts(schemaId, this.limitToFields).then(
        (response) => {
          this.items = response.data.map((item) => {
            return {
              prompt: item.ai,
              key: item.key,
              path: item.path,
              selected: true,
              value: '',
            }
          })
        }
      )
    },
    createCase() {
      TemporaryDocumentService.create().then((response) => {
        const caseUuid = response.case_uuid.split('/').pop()
        this.temporaryFolderId = caseUuid

        this.$router.replace({
          params: {
            quoteId: this.$route.params.schemaId ?? this.$route.params.quoteId,
            temporaryFolderId: caseUuid,
          },
        })
      })
    },
    fetchFiles() {
      if (!this.temporaryFolderId) {
        this.createCase()
      }

      TemporaryDocumentService.list(this.temporaryFolderId).then((response) => {
        this.attachments = response
        if (this.attachments.length === 0) {
          return
        }
        this.selectedAttachment = this.attachments[0].current_filename
      })
    },
    openUploadModal() {
      const input = document.querySelector('input[type="file"]')
      input.click()
    },
    uploadFile(event) {
      const file = event.target.files[0]
      const temporaryFolderId = this.temporaryFolderId
      const payload = new FormData()
      payload.append('case_uuid', temporaryFolderId)
      payload.append('file', file)
      TemporaryDocumentService.upload(payload).then(() => {
        this.fetchFiles()
      })
    },
    setMoveFilesMeta() {
      this.setMetaProperty({
        node: 'aiAssistant',
        property: 'moveFiles',
        value: this.moveFiles,
      })
    },
  },
}
</script>
<style>
.vue-slideout-lock-scroll {
  overflow: scroll !important;
}
.vue-slideout {
  pointer-events: none;
}
.vue-slideout-layout {
  pointer-events: all;
}
.vue-slideout .custom-control-label {
  width: 100% !important;
}
</style>
