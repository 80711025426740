<template>
  <div class="wrap-text">
    {{ user.name }} | {{ user.entity.name }}
  </div>
</template>
<script>
import SearchRowMixin from '@/Modules/GlobalSearch/Components/SearchRowMixin.js'
import UserModel from '@/Modules/User/UserModel.js'

export default {
  name: 'UserRowComponent',

  mixins: [ SearchRowMixin ],

  data() {
    return {
      user: new UserModel(this.model || {})
    }
  }
}
</script>
