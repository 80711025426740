<template>
  <b-toast
    v-model="informationToast.show"
    :variant="informationToast.variant"
    :title="informationToast.title"
    auto-hide-delay="5000"
    @hide="hideToast"
  >
    {{ informationToast.text }}
  </b-toast>
</template>

<script>
import { HIDE_INFORMATION_TOAST_ACTION, INFORMATION_TOAST_GETTER } from '@/Modules/Base/BaseModule.js'
import { mapGetters } from 'vuex'

export default {
  name: 'InformationToast',

  computed: {
    ...mapGetters('BaseModule', {
      informationToast: INFORMATION_TOAST_GETTER,
    })
  },

  methods: {
    async hideToast () {
      await this.$store.dispatch(`BaseModule/${ HIDE_INFORMATION_TOAST_ACTION }`)
    }
  }
}
</script>
