<template>
  <div
    class="position-relative col-auto"
    :class="{ [uiOptions.classColumn]: true }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <TextAreaField
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :custom-class="uiOptions.class"
      :label="__(node.label)"
      :description="__(node.description)"
      :name="name"
      :regex="uiOptions.regex"
      :required="uiOptions.required"
      :quote-node="node"
      :readonly="readOnly"
      :placeholder="__(uiOptions.placeholder)"
      :datamask="uiOptions.datamask"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :tooltip-class="node.uiProperties.tooltipClass"
      :hint="__(node.uiOptions.hint)"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeTextArea',

  mixins: [UiTypeMixin],
}
</script>
