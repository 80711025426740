<template>
  <div>
    <PreviewBlock />
    <CoverholderNavigationToggle
      v-if="!fullScreen"
      ref="navbarToggler"
      :expanded="navigationExpanded"
      :on-toggle="handleToggle"
      :visible="navigationCollapseVisible"
    />
    <CoverholderNavigation
      v-if="!fullScreen"
      :on-mouse-over="handleNavMouseOver"
      :expanded="navigationExpanded"
      @resize="handleResize"
    />
    <div
      :class="{ 'full-width': !navigationExpanded }"
      class="navigation-collapse-sidebar"
      @mouseover="onCollapseSidebarMouseOver"
      @mouseout="onCollapseSidebarMouseOut"
    ></div>

    <div class="main-content" :class="{ 'full-width': !navigationExpanded }">
      <CoverholderHeader v-if="!fullScreen" />
      <div class="d-flex position-relative">
        <b-container fluid>
          <RouterView />
        </b-container>
        <HelpSidebar />
      </div>
    </div>
  </div>
</template>

<script>
import CoverholderHeader from '@/Modules/Base/Components/CoverholderHeader.vue'
import CoverholderNavigation from '@/Modules/Base/Components/CoverholderNavigation.vue'
import CoverholderNavigationToggle from '@/Modules/Base/Components/CoverholderNavigationToggle.vue'
import PreviewBlock from '@/Modules/Base/Components/PreviewBlock.vue'
import HelpSidebar from '@/Modules/Base/Components/HelpSidebar.vue'
import AuthService from '@/Modules/Auth/AuthService.js'

export default {
  name: 'CoverholderApp',

  components: {
    CoverholderNavigation,
    CoverholderHeader,
    CoverholderNavigationToggle,
    PreviewBlock,
    HelpSidebar,
  },

  data() {
    return {
      navigationExpanded: true,
      navigationCollapseVisible: false,
    }
  },

  computed: {
    fullScreen() {
      return (
        this.$route.name === 'reports.show-designer' ||
        this.$route.name === 'reports.design-report'
      )
    },
  },

  watch: {
    navigationExpanded(newValue) {
      this.updateApplicationSidebar({
        expanded: newValue,
        collapseVisible: !newValue,
      })
    },
  },

  async created() {
    await AuthService.redirectSpecialRoleUser()

    this.setSidebarFromApplicationData()
  },

  methods: {
    handleToggle({ collapse }) {
      this.navigationExpanded = !collapse
      if (!this.navigationExpanded) {
        this.navigationCollapseVisible = true
      }
    },

    handleResize({ isTablet }) {
      if (!isTablet) {
        return
      }
      this.navigationExpanded = true
    },

    handleNavMouseOver(event) {
      if (!this.navigationCollapseVisible) {
        this.navigationCollapseVisible = true
      }
    },

    containsTogglerElement(event) {
      const togglerElement = this.$refs.navbarToggler?.$el
      if (!togglerElement || !event) return
      return togglerElement.contains(event.toElement)
    },

    onCollapseSidebarMouseOver(event) {
      if (!this.navigationCollapseVisible) {
        this.navigationCollapseVisible = true
      }
    },

    onCollapseSidebarMouseOut(event) {
      if (!this.navigationExpanded) {
        this.navigationCollapseVisible = true
        return
      }
      if (!this.containsTogglerElement(event)) {
        this.navigationCollapseVisible = false
      }
    },

    updateApplicationSidebar(
      { expanded, collapseVisible },
      itemKey = 'sidebar_nav'
    ) {
      if (localStorage) {
        localStorage.setItem(
          itemKey,
          JSON.stringify({
            expanded,
            collapse_visible: collapseVisible,
          })
        )
      }
    },

    setSidebarFromApplicationData(itemKey = 'sidebar_nav') {
      if (localStorage) {
        const sidebarNavOptions = localStorage.getItem(itemKey)
        if (!sidebarNavOptions) {
          return
        }
        try {
          const { expanded, collapse_visible } = JSON.parse(sidebarNavOptions)
          this.navigationExpanded = expanded
          this.navigationCollapseVisible = collapse_visible
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main-content {
  transition: all 0.4s;

  &.full-width {
    margin-left: 45px !important;
  }
}
.navigation-collapse-sidebar {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 250px;
  width: 25px;
  background: none;
  z-index: 1001;

  &.full-width {
    left: 0;
  }
}
@media (min-width: 992px) {
  .navigation-collapse-sidebar {
    display: block;
  }
}
</style>
