<template>
  <DirectoryListItemTemplate :style="directoryStyle" selectable>
    <template #checkbox>
      <Checkbox
        :checked="item.checked"
        :name="'selectItem.' + item.id"
        class="mb-0 mr-0"
        label=""
        @onChange="
          (event) => {
            onItemClick(event, item)
          }
        "
      />
    </template>
    <template #iconBlock>
      <img :src="getFileTypeIcon(item)" :alt="item.name" width="48" />
    </template>
    <template #label>
      <a
        v-can:quotes.documents.download
        href="#"
        @click.prevent="preview(item.id, item)"
      >
        {{ item.name.replace(/\.[.pdf|.docx|.doc|.xls]+$/, '') }}
      </a>
    </template>
    <template #badge>
      <DirectoryBadge :item="item" />
    </template>
    <template #description>
      <DocumentRowSummary v-if="item" :document="item" />
    </template>
    <template #documentType>
      <Badge v-if="item.type" variant="soft-info">
        {{ item.type.name }}
      </Badge>
    </template>
    <template #actionButtons>
      <ActionPopover
        :id="'directory-' + item.id"
        v-can-any="['quotes.documents.download', 'documents.destroy']"
      >
        <b-button
          v-can:quotes.documents.download
          class="dropdown-item"
          size="sm"
          @click="download(item.id)"
        >
          <Icon icon="cloud-download" class="mr-2" size="14" />
          {{ __('buttons.download') }}
        </b-button>

        <b-button
          v-can:documents.destroy
          class="dropdown-item"
          size="sm"
          @click="remove(item.id)"
        >
          <Icon icon="trash" class="mr-2" size="14" />
          {{ __('buttons.delete') }}
        </b-button>

        <b-button
          v-can:documents.update
          class="dropdown-item"
          size="sm"
          @click="moveDocument(item)"
        >
          <Icon icon="arrow-right" class="mr-2" size="14" />
          {{ __('buttons.move') }}
        </b-button>
      </ActionPopover>
    </template>
  </DirectoryListItemTemplate>
</template>

<script>
import DirectoryListItemTemplate from '@/Modules/Quote/QuoteDocument/Components/DirectoryListItemTemplate.vue'
import QuoteDocumentService from '@/Modules/Quote/QuoteDocument/QuoteDocumentService.js'
import DocumentRowSummary from '@/Modules/Document/Components/DocumentRowSummary.vue'
import DirectoryMixin from '@/Modules/Quote/QuoteDocument/Components/DirectoryMixin.js'
import DirectoryBadge from '@/Modules/Quote/QuoteDocument/Components/DirectoryBadge.vue'
import MoveDocumentModal from '@/Modules/Quote/QuoteDocument/Components/MoveDocumentModal.vue'

export default {
  name: 'DirectoryFile',

  components: { DirectoryListItemTemplate, DocumentRowSummary, DirectoryBadge },

  mixins: [DirectoryMixin],

  inject: ['onFileSelect', 'onRemoveFileSuccess', 'onMoveFileSuccess'],

  methods: {
    onItemClick(event, item) {
      this.onFileSelect && this.onFileSelect(event, item)
    },

    async preview(id, item = null) {
      if (item && item.fileType !== 'pdf') {
        this.download(id)
        return
      }
      let file = null
      await QuoteDocumentService.preview(this.$route.params.quoteId, id).then(
        (response) => (file = response)
      )
      if (!file) return

      const contentType = _.get(file.headers, 'content-type')
      const url = URL.createObjectURL(
        new Blob([file.data], { type: contentType })
      )

      let pdfWindow = window.open()
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" + url + "'></iframe>"
      )
    },

    download(id) {
      QuoteDocumentService.show(this.$route.params.quoteId, id)
    },

    remove(id) {
      this.$showConfirmationModal(
        this.__('documents.confirmation-delete'),
        () => {
          QuoteDocumentService.delete(this.$route.params.quoteId, id).then(
            () => {
              this.$successToast(this.__('documents.delete-success'))
              this.onRemoveFileSuccess(this.item)
            }
          )
        }
      )
    },

    moveDocument(item) {
      QuoteDocumentService.listFolders(this.$route.params.quoteId, {}).then(
        (response) => {
          this.showModal({
            component: MoveDocumentModal,
            label: this.__('documents.move'),
            icon: 'arrow-right',
            class: 'modal-sm',
            meta: {
              quoteId: this.$route.params.quoteId,
              documentFolders: response?.data?.data,
              document: {
                id: item.id,
                name: item.name,
              },
            },
            onSuccess: () => {
              this.onMoveFileSuccess()
            },
          })
        }
      )
    },
  },
}
</script>
