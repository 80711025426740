
<div
  class="node-overlay overlay"
  :class="{ active }"
  @click="onClick"
  @drop="onDrop"
  @dragover="dragover"
  @dragenter="dragenter"
  @dragleave="dragleave"
>
  <slot name="node-controls" />
</div>
