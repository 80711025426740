<template>
  <div>
    <b-button
      id="notification-popover-button"
      class="btn-notification dropdown-toggle mr-3 d-none d-lg-flex align-items-center"
    >
      <icon icon="bell" class="text-muted" :size="iconSize"></icon>

      <b-badge
        v-if="unreadNotificationsCount"
        variant="danger"
        class="badge-notification"
      >
        {{ applyCompactNotation(unreadNotificationsCount) }}
      </b-badge>
    </b-button>

    <b-popover
      target="notification-popover-button"
      :triggers="['hover', 'click']"
      placement="bottom"
      custom-class="notification-popover"
      @show="fetchLatestNotifications()"
    >
      <template #title>
        <div class="d-flex justify-content-between align-center mt-2">
          <h4 class="mb-0">
            <icon icon="bell" class="text-muted" />
            {{ __('notifications.popover-title') }}
          </h4>
          <RouterLink
            :to="{ name: 'notifications.list' }"
            class="small align-self-center"
          >
            {{ __('routes.view-all') }}
          </RouterLink>
        </div>
      </template>

      <hr />

      <b-list-group v-if="notificationsLoaded" flush class="my-n3">
        <b-list-group-item
          v-for="(notification, key) in notifications"
          :key="key"
          class="text-reset notification-popover__wrapper"
          @click="goToDetails(notification)"
        >
          <b-row class="align-items-center">
            <b-col cols="auto">
              <div class="text-primary">
                <div class="notification-circle">
                  <icon
                    :icon="notification.data.icon || 'bell'"
                    :class="{ 'text-muted': notification.readAt }"
                  />
                </div>
              </div>
            </b-col>

            <b-col class="ml-n3">
              <div class="small notification-popover__wrapper__title">
                <strong
                  :class="{
                    'text-muted': notification.readAt,
                    'dark-color': true,
                  }"
                >
                  {{ notification.data.title }}
                </strong>
              </div>

              <small
                :class="{
                  'text-muted': notification.readAt,
                  'dark-color': true,
                }"
              >
                {{ notification.createdAt }}
              </small>
            </b-col>
          </b-row>
        </b-list-group-item>
        <span v-if="!notifications.length" class="mb-2">
          {{ __('notifications.popover-no-notifications') }}
        </span>
      </b-list-group>
    </b-popover>
  </div>
</template>

<script>
import PopOverMixin from '@/Modules/Base/Mixins/PopOverMixin.js'
import NotificationService from '@/Modules/Notification/NotificationService.js'
import NotificationManager from '@/Modules/Notification/Managers/NotificationManager.js'
import { applyCompactNotation } from '@/General/applyNumberFormatting.js'
import NotificationModel from '@/Modules/Notification/NotificationModel.js'
import { NOTIFICATION_CHANNELS } from '@/Modules/User/UserModel.js'

export default {
  name: 'NotificationPopover',

  mixins: [PopOverMixin],
  data() {
    return {
      unreadNotificationsCountUpdated: null,
      defaultUnreadCountRefreshTime: 30000, // Fallback if user sets 0 or null in settings
      notificationsLoaded: false,
      unreadNotifications: [],
      notificationsLoader: null,
    }
  },
  computed: {
    notifications() {
      return this.isLiveNotificationsEnabled
        ? this.unreadNotifications
        : this.authUser().unreadNotifications
    },
    unreadNotificationsCount() {
      return (
        this.unreadNotificationsCountUpdated ??
        this.authUser().unreadNotificationsCount
      )
    },
    isLiveNotificationsEnabled() {
      return this.authUser().notificationChannels?.some(
        (channel) => channel.value === NOTIFICATION_CHANNELS.DATABASE
      )
    },
  },
  beforeDestroy() {
    clearInterval(this.notificationsLoaded)
  },
  mounted() {
    this.unreadNotificationsCountUpdated =
      this.authUser().unreadNotificationsCount
    if (!this.isLiveNotificationsEnabled) {
      this.notificationsLoaded = true
    }

    const unreadCountRefreshTimeInSeconds = this.setting(
      'ui_notification_refresh_time_in_seconds'
    )
    if (this.isLiveNotificationsEnabled) {
      this.notificationsLoaded = setInterval(
        () => {
          NotificationService.unreadCount().then((response) => {
            this.unreadNotificationsCountUpdated = response.data.count
          })
        },
        unreadCountRefreshTimeInSeconds
          ? unreadCountRefreshTimeInSeconds * 1000
          : this.defaultUnreadCountRefreshTime
      )
    }
  },
  methods: {
    fetchLatestNotifications() {
      if (!this.isLiveNotificationsEnabled) return

      this.notificationsLoaded = false
      NotificationService.latest().then((response) => {
        let unreadNotifications = []
        response.data.data.forEach((notification) => {
          unreadNotifications.push(new NotificationModel(notification))
        })
        this.unreadNotifications = unreadNotifications
        this.notificationsLoaded = true
      })
    },
    applyCompactNotation(value) {
      return applyCompactNotation(value, this.setting('formatting_value'))
    },

    goToDetails(notification) {
      this.$root.$emit('bv::hide::popover', 'notification-popover-button')
      if (notification.readAt) {
        NotificationService.unread(notification.id).then(() => {
          notification.readAt = null
          this.authUser().unreadNotificationsCount += 1
          this.unreadNotificationsCountUpdated += 1
        })

        return
      }

      NotificationService.read(notification.id).then(() => {
        notification.readAt = new Date().toDateString()
        this.authUser().unreadNotificationsCount -= 1
        this.unreadNotificationsCountUpdated -= 1

        NotificationManager.handle(notification)
      })
    },
  },
}
</script>

<style lang="scss">
.badge-notification {
  z-index: 1;
}

.notification-popover {
  max-width: 350px;
  width: 350px;

  .dark-color {
    color: #12263f;
  }

  &__wrapper {
    cursor: pointer;

    &:not(:first-child) {
      .notification-circle:before {
        height: 39px;
        width: 1px;
        background: #d5e5fa;
        content: '';
        display: block;
        position: absolute;
        top: -36px;
      }
    }

    &__title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 262px;
    }
  }

  .list-group-item {
    border: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .notification-circle {
    background: #d5e5fa;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;

    i {
      line-height: 1;
      font-size: 20px !important;
    }
  }
}
</style>
