
<div
  v-if="uiOptions.visible"
  :class="uiOptions.class"
  class="position-relative"
  :draggable="isDraggable"
  @dragstart="event => $emit('drag-start', event)"
  @dragend="event => $emit('drag-end', event)"
>
  <TextField
    :ref="snakeKey"
    v-model="node.value"
    is-currency
    :precision="uiOptions.precision"
    :min="uiOptions.min"
    :max="uiOptions.max"
    :visible="uiOptions.visible"
    :label="node.label"
    :description="node.description"
    :name="name"
    v-bind="$attrs"
    :required="uiOptions.required"
    :readonly="readOnly"
    :placeholder="uiOptions.placeholder"
    :datamask="uiOptions.datamask"
    :highlight="node.uiProperties.highlight"
    :tooltip="node.uiProperties.tooltip"
  >
    <template slot="append">
      <div>
        <div class="input-group-append">
          <span
            style="border-bottom-left-radius: 0; border-top-left-radius: 0;"
            class="input-group-text readonly text-uppercase"
          >
            {{ currencyCode }}
          </span>
        </div>
      </div>
    </template>
  </TextField>
  <slot name="node-actions" />
</div>
