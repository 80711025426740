
<div>
  <PreviewBlock />
  <CoverholderNavigationToggle
    v-if="!fullScreen"
    ref="navbarToggler"
    :expanded="navigationExpanded"
    :on-toggle="handleToggle"
    :visible="navigationCollapseVisible"
  />
  <CoverholderNavigation
    v-if="!fullScreen"
    :on-mouse-over="handleNavMouseOver"
    :expanded="navigationExpanded"
    @resize="handleResize"
  />
  <div
    :class="{ 'full-width': !navigationExpanded }"
    class="navigation-collapse-sidebar"
    @mouseover="onCollapseSidebarMouseOver"
    @mouseout="onCollapseSidebarMouseOut"
  ></div>

  <div class="main-content" :class="{ 'full-width': !navigationExpanded }">
    <CoverholderHeader v-if="!fullScreen" />
    <div class="d-flex position-relative">
      <b-container fluid>
        <RouterView />
      </b-container>
      <HelpSidebar />
    </div>
  </div>
</div>
