
<Component
  :is="node.component"
  :node="node"
  designer-mode
  @drag-start="dragstart"
  @drag-end="dragend"
>
  <template #node-actions>
    <NodeOverlay
      :active="showOverlay"
      :style="{
        'z-index': zIndex,
      }"
      @click="onClick"
      @drop="onDrop"
    >
      <template v-if="node.parent" #node-controls>
        <NodeControls
          :on-copy="handleCopyClick"
          :on-remove="handleRemoveClick"
        />
      </template>
    </NodeOverlay>
    <NodePortOverlay
      v-if="node.canInsertNode && node.parent"
      :style="{
        'z-index': zIndex,
      }"
      @drop="onDropPort"
    />
  </template>
</Component>
