<template>
  <div
    class="position-relative col-auto"
    :class="{ [uiOptions.class]: true }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <SelectField
      v-if="uiOptions.multiple"
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="__(node.description)"
      :icon="uiOptions.icon"
      :name="name"
      :quote-node="node"
      :required="uiOptions.required"
      :readonly="readOnly"
      :placeholder="__(uiOptions.placeholder)"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :tooltip-class="node.uiProperties.tooltipClass"
      v-bind="$attrs"
      taggable
      multiple
      :options="[]"
      :open-direction="node.uiProperties.openDirection"
      :hint="__(node.uiOptions.hint)"
    />
    <TextField
      v-else
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="__(node.description)"
      :icon="uiOptions.icon"
      type="email"
      :name="name"
      :quote-node="node"
      :regex="uiOptions.regex"
      :required="uiOptions.required"
      :readonly="readOnly"
      :placeholder="__(uiOptions.placeholder)"
      :datamask="uiOptions.datamask"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :tooltip-class="node.uiProperties.tooltipClass"
      :input-class="node.uiProperties.inputClass"
      :hint="__(node.uiOptions.hint)"
      v-bind="$attrs"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
import TraceModeMixin from '@/General/Form/Mixins/TraceModeMixin.js'
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeEmail',

  mixins: [UiTypeMixin, TraceModeMixin],
}
</script>
