<template>
  <div
    v-if="groups"
    class="global-search-dropdown shadow"
    data-testid="search-dropdown"
    tabindex="0"
  >
    <div
      v-for="(items, group) in groups"
      :key="group"
      class="item-block border-top py-2"
    >
      <div class="px-3 font-weight-bold d-flex align-items-center">
        <Icon
          v-if="GlobalSearchUtilities.dataByType(group)"
          :icon="GlobalSearchUtilities.dataByType(group).icon"
          class="mr-2"
          size="18"
        />
        {{ lodash.upperFirst(group) }}
      </div>
      <b-dropdown-item
        v-for="item in items"
        :key="item.searchable.id"
        :active="item.active"
        @click="$emit('dropdownItemClick', group, item)"
      >
        <component
          :is="GlobalSearchUtilities.dataByType(group).component"
          v-if="GlobalSearchUtilities.dataByType(group)"
          :model="item.searchable"
        />
      </b-dropdown-item>
    </div>
  </div>
</template>
<script>
import GlobalSearchUtilities from '@/Modules/GlobalSearch/GlobalSearchUtilities.js'

export default {
  name: 'GlobalSearchDropdown',

  props: {
    groups: {
      type: Object,
      default: null,
    },
  },

  created() {
    this.lodash = _
    this.GlobalSearchUtilities = GlobalSearchUtilities
  },

  mounted() {
    this.$emit('open')
  },
}
</script>
<style lang="scss">
.global-search-dropdown {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 59px;
  background: #ffffff;
  border: 1px solid #e3ebf6;
  border-top: 0;
  border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
  max-height: 350px;
  overflow-y: auto;
  max-width: 700px;

  .dropdown-item {
    padding: 0.375rem 21px;

    .wrap-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  li {
    list-style-type: none;
  }

  .dropdown-toggle {
    display: none;
  }
}
</style>
