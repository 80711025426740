<template>
  <div
    class="position-relative col-auto"
    :class="{ [uiOptions.class]: true }"
    :draggable="isDraggable"
    @dragstart="(event) => $emit('drag-start', event)"
    @dragend="(event) => $emit('drag-end', event)"
  >
    <TextField
      :ref="snakeKey"
      v-model="node.value"
      :visible="uiOptions.visible"
      :label="__(node.label)"
      :description="__(node.description)"
      :icon="uiOptions.icon"
      type="text"
      :name="name"
      :quote-node="node"
      :regex="uiOptions.regex"
      :required="uiOptions.required"
      :readonly="readOnly"
      :placeholder="uiOptions.placeholder"
      :datamask="uiOptions.datamask"
      :highlight="node.uiProperties.highlight"
      :tooltip="node.uiProperties.tooltip"
      :tooltip-class="node.uiProperties.tooltipClass"
      :input-class="node.uiProperties.inputClass"
      v-bind="$attrs"
    />
    <slot name="node-actions" />
  </div>
</template>

<script>
import UiTypeMixin from '@/Modules/Quote/Components/QuoteForm/UiTypeMixin.js'

export default {
  name: 'UiTypeText',

  mixins: [UiTypeMixin],
}
</script>
