
<div>
  <Slideout
    ref="slideout"
    :visible="true"
    dock="bottom"
    :title="'AI Assistant'"
    :show-close="false"
    :show-mask="false"
    :close-on-mask-click="false"
    :resizable="expanded"
    :allow-resize="expanded"
    :ignore-esc="true"
    append-to="body"
    @close="hide"
    @resize="onResize"
  >
    <template #btn>
      <b-row class="align-items-center">
        <b-col>
          <b-checkbox
            v-model="moveFiles"
            style="width: 200px"
            @change="setMoveFilesMeta"
          >
            {{ __('aiAssistant.checkbox.move-files') }}
          </b-checkbox>
        </b-col>
        <b-col>
          <b-button
            size="sm"
            variant="primary"
            :disabled="attachments.length === 0"
            @click="extract"
          >
            {{ __('aiAssistant.buttons.extract') }}
          </b-button>
        </b-col>
        <b-col>
          <b-button size="sm" variant="outline-primary" @click="fetchFields">
            {{ __('aiAssistant.buttons.reload') }}
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-if="expanded"
            size="sm"
            variant="link"
            @click="collapse"
          >
            <Icon class="grey ml-2" icon="angle-down" />
          </b-button>
          <b-button v-else size="sm" variant="link" @click="expand">
            <Icon class="grey ml-2" icon="angle-up" />
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="link" @click="hide">
            <Icon class="grey ml-2" icon="times" />
          </b-button>
        </b-col>
      </b-row>
    </template>
    <div :class="isExpanded">
      <b-card-group class="h-100">
        <b-card class="col-3 pl-2 rounded-0" body-class="pl-0 pr-3 pt-3">
          <b-card-title>
            <b-row class="justify-content-between align-items-center">
              <b-col> Select file </b-col>
              <b-col class="text-right">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="openUploadModal"
                >
                  <Icon icon="cloud-upload" size="14" class="mr-2" />
                  {{ __('buttons.upload') }}
                </b-button>
                <input type="file" class="d-none" @change="uploadFile" />
              </b-col>
            </b-row>
          </b-card-title>
          <div class="ml-2 text-left">
            <div
              v-for="attachment in attachments"
              :key="attachment.current_filename"
              class="mb-2"
            >
              <b-form-radio
                v-model="selectedAttachment"
                :value="attachment.current_filename"
              >
                <b-row
                  class="align-items-center justify-content-between"
                  style="margin-top: -5px"
                >
                  <b-col>
                    <img
                      :id="`attachment-icon-${attachment.current_filename}`"
                      :key="`attachment-icon-${attachment.current_filename}`"
                      width="24"
                      :src="getFileTypeIcon(attachment.current_filename)"
                      class="mr-2 cursor-pointer"
                      :alt="attachment.current_filename"
                    />

                    {{ attachment.current_filename }}
                  </b-col>
                  <b-col class="col-1">
                    <b-button
                      size="sm"
                      variant="link"
                      class="m-0 p-0"
                      @click.prevent="preview(attachment)"
                    >
                      <Icon class="grey" icon="external-link-alt" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-radio>
            </div>
          </div>
        </b-card>

        <b-card class="pl-2 rounded-0" body-class="pl-0 pr-3 pt-3">
          <b-card-title>Edit Prompts</b-card-title>
          <b-table
            hover
            :items="items"
            :fields="fields"
            small
            borderless
            @row-clicked="onRowClicked"
          >
            <template #cell(selected)="row">
              <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
            </template>
            <template #cell(key)="row">
              <span>{{ row.item.key }} ({{ row.item.path }})</span>
            </template>
            <template #cell(prompt)="row">
              <b-input v-model="row.item.prompt" />
            </template>
            <template #cell(value)="row">
              <b-row class="justify-content-between align-items-center">
                <b-col class="col-11">
                  <input
                    v-model="row.item.value"
                    disabled
                    :class="{
                      'form-control': true,
                      readonly: true,
                    }"
                  />
                </b-col>
                <b-col class="col-1">
                  <b-button
                    size="sm"
                    variant="link"
                    class="m-0 p-0"
                    @click="scrollToElement(row.item.path)"
                  >
                    <Icon class="grey" icon="crosshair" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-card>
      </b-card-group>
    </div>
  </Slideout>
</div>
