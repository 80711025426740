
<DirectoryListItemTemplate :style="directoryStyle" selectable>
  <template #checkbox>
    <Checkbox
      :checked="item.checked"
      :name="'selectItem.' + item.id"
      class="mb-0 mr-0"
      label=""
      @onChange="
        (event) => {
          onItemClick(event, item)
        }
      "
    />
  </template>
  <template #iconBlock>
    <img :src="getFileTypeIcon(item)" :alt="item.name" width="48" />
  </template>
  <template #label>
    <a
      v-can:quotes.documents.download
      href="#"
      @click.prevent="preview(item.id, item)"
    >
      {{ item.name.replace(/\.[.pdf|.docx|.doc|.xls]+$/, '') }}
    </a>
  </template>
  <template #badge>
    <DirectoryBadge :item="item" />
  </template>
  <template #description>
    <DocumentRowSummary v-if="item" :document="item" />
  </template>
  <template #documentType>
    <Badge v-if="item.type" variant="soft-info">
      {{ item.type.name }}
    </Badge>
  </template>
  <template #actionButtons>
    <ActionPopover
      :id="'directory-' + item.id"
      v-can-any="['quotes.documents.download', 'documents.destroy']"
    >
      <b-button
        v-can:quotes.documents.download
        class="dropdown-item"
        size="sm"
        @click="download(item.id)"
      >
        <Icon icon="cloud-download" class="mr-2" size="14" />
        {{ __('buttons.download') }}
      </b-button>

      <b-button
        v-can:documents.destroy
        class="dropdown-item"
        size="sm"
        @click="remove(item.id)"
      >
        <Icon icon="trash" class="mr-2" size="14" />
        {{ __('buttons.delete') }}
      </b-button>

      <b-button
        v-can:documents.update
        class="dropdown-item"
        size="sm"
        @click="moveDocument(item)"
      >
        <Icon icon="arrow-right" class="mr-2" size="14" />
        {{ __('buttons.move') }}
      </b-button>
    </ActionPopover>
  </template>
</DirectoryListItemTemplate>
